import { TokenType, TokenTypes } from "./TokenType";
import { AbstractTokenConfig } from "./AbstractTokenConfig";
import { ValidationErrors } from "./ErrorMessage";

interface DateConfigData {
  type: TokenType;
  isRequired: boolean;
  dateFormat: string;
}

export class DateConfig extends AbstractTokenConfig {
  static __type: TokenType = TokenTypes.date as TokenType;

  public constructor(
    public isRequired: boolean = false,
    public dateFormat: string = "dd/mm/yyyy"
  ) {
    super(DateConfig.__type as TokenType);
  }

  public static fromJson(data: DateConfigData): DateConfig {
    return new DateConfig(data.isRequired, data.dateFormat);
  }

  public toJson(): DateConfigData {
    return {
      type: DateConfig.__type,
      isRequired: this.isRequired,
      dateFormat: this.dateFormat,
    };
  }

  public copyWith(
    isRequired: boolean | undefined,
    dateFormat: string | undefined
  ): DateConfig {
    return new DateConfig(
      isRequired === undefined ? this.isRequired : isRequired,
      dateFormat === undefined ? this.dateFormat : dateFormat
    );
  }

  public validateRules(): Array<ValidationErrors> {
    return [];
  }

  public validateInput(input: string): Array<ValidationErrors> {
    if (input === "" && this.isRequired) {
      return [{ isValid: false, message: "Input is required" }];
    }

    const dateFormatRegex = this.getDateFormatRegex(this.dateFormat);
    if (!dateFormatRegex.test(input)) {
      return [
        { isValid: false, message: `Invalid date format (${this.dateFormat})` },
      ];
    }

    if (!isValidDate(input, this.dateFormat)) {
      return [{ isValid: false, message: "Invalid date" }];
    }

    return [];
  }

  private getDateFormatRegex(dateFormat: string): RegExp {
    switch (dateFormat) {
      case "dd/mm/yyyy":
        return /^\d{1,2}\/\d{1,2}\/\d{4}$/;
      case "mm-dd-yyyy":
        return /^\d{1,2}-\d{1,2}-\d{4}$/;
      case "dd-mm-yyyy":
        return /^\d{1,2}-\d{1,2}-\d{4}$/;
      case "mm/dd/yyyy":
        return /^\d{1,2}\/\d{1,2}\/\d{4}$/;
      default:
        throw new Error(`Invalid date format: ${dateFormat}`);
    }
  }
}

function isValidDate(dateString: string, dateFormat: string): boolean {
  const formatParts = dateFormat.split(/[-/]/);
  const dateParts = dateString.split(/[-/]/);

  const day = parseInt(dateParts[formatParts.indexOf("dd")], 10);
  const month = parseInt(dateParts[formatParts.indexOf("mm")], 10) - 1;
  const year = parseInt(dateParts[formatParts.indexOf("yyyy")], 10);

  const date = new Date(year, month, day);

  return (
    !isNaN(date.getTime()) &&
    date.getDate() === day &&
    date.getMonth() === month &&
    date.getFullYear() === year
  );
}
