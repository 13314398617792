import { BaseService } from "../../../services/baseService";
import { BusinessUserWithBusinessName } from "../redux/userRoleSlice";
import { BusinessUser } from "../../business/redux/businessUserSlice";

export class UserRoleService extends BaseService {
  static ROOT_URL = "/business-role/user";

  public fetchUserRoles = async (): Promise<BusinessUserWithBusinessName[]> => {
    return await this.genAuthenticatedGetX(UserRoleService.ROOT_URL);
  };

  public updateUsersBusinessRole = async (
    bizUser: BusinessUser
  ): Promise<void> => {
    await this.genAuthenticatedPatchX(
      `${UserRoleService.ROOT_URL}/${bizUser.businessUuid}`,
      {
        role: bizUser.role,
        invitationStatus: bizUser.invitationStatus,
      }
    );
  };

  public leaveBusiness = async (businessUuid: string): Promise<void> => {
    await this.genAuthenticatedDeleteX(
      `${UserRoleService.ROOT_URL}/${businessUuid}`
    );
  };
}
