import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { DatasetHome } from "./dataset/DatasetHome";
import { BusinessPage } from "./business/BusinessPage";
import { LandingPage } from "./landing/LandingPage";
import { SignIn } from "./auth/SignInX";
import { SignUp } from "./auth/SignUp";
import { AuthContaienr } from "./auth/authContainer";
import DasboardPage from "./dashboard/DasboardPage";
import { UserRoles } from "./user/UserRoles";
import UserSettingsPage from "./user/UserSettingsPage";
import { Home } from "./business/Home";
import ForgotPassword from "./auth/ForgotPassword";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/business/:uuid",
    element: <BusinessPage />,
  },
  {
    path: "/dataset/:uuid",
    element: <DatasetHome />,
  },
  {
    path: "/user",
    // TODO(Taman): fix this name
    element: <DasboardPage />,
    children: [
      {
        path: "/user/home",
        element: <Home />,
      },
      {
        path: "/user/roles",
        element: <UserRoles />,
      },
      {
        path: "/user/settings",
        element: <UserSettingsPage />,
      },
      {
        path: "/user",
        element: <div></div>,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthContaienr />,
    children: [
      {
        path: "/auth/signin",
        element: <SignIn />,
      },
      {
        path: "/auth/signup",
        element: <SignUp />,
      },
      {
        path: "/auth/forgot-password",
        element: <ForgotPassword/>,
      }
    ],
  },
  {
    path: "*",
    element: <div>404</div>,
  },
]);
