import { useEffect, useState } from "react";
import "../../services/rules/DigitTokenConfig";
import { LetterTokenConfig } from "../../services/rules/LetterTokenConfig";
import { ValidationErrors } from "../../services/rules/ErrorMessage";
import {
  selectField,
  updateFieldThunkFunction,
} from "../fieldMetadata/redux/fieldSlice";
import { useAppSelector } from "../../redux/hooks";
import { SaveButton } from "./components/SaveButton";
import { useDispatch } from "react-redux";
import { RuleEditorProps } from "./ruleEditorPropsInterface";

export const TextRuleEditor = (props: RuleEditorProps) => {
  const [minLength, setMinLength] = useState<number>(
    LetterTokenConfig.DEFAULT_MIN_LENGTH
  );
  const [maxLength, setMaxLength] = useState<number>(
    LetterTokenConfig.DEFAULT_MAX_LENGTH
  );
  const [allowedCharacters, setAllowedCharacters] = useState<string[]>([]);
  const [disallowedCharacters, setDisallowedCharacters] = useState<string[]>(
    []
  );
  const [isWhitespaceAllowed, setIsWhitespaceAllowed] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationErrors[]>([]);

  const field = useAppSelector((state) => selectField(state, props.fieldUuid));

  const rule: string | null = field?.configStr ?? null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!rule) {
      return;
    }
    const parsedRule = JSON.parse(rule);
    const config = LetterTokenConfig.fromJson(parsedRule);
    setMinLength(config.minLength);
    setMaxLength(config.maxLength);
    setAllowedCharacters(config.allowedCharacters);
    setDisallowedCharacters(config.disallowedCharacters);
    setIsWhitespaceAllowed(config.isWhitespaceAllowed);
  }, [rule]);
  if (rule == null) {
    return <div>Rule not found</div>;
  }

  const handleSave = () => {
    if (field == null) {
      return;
    }
    const updatedConfig = new LetterTokenConfig(
      minLength,
      maxLength,
      allowedCharacters,
      disallowedCharacters,
      isWhitespaceAllowed
    );
    try {
      const errors: Array<ValidationErrors> = updatedConfig.validateRules();
      if (errors.length > 0) {
        setErrors(errors);
        return;
      } else {
        setErrors([]);
      }
    } catch (e: any) {
      alert(`Failed to validate rules: ${e.message}`);
      return;
    }

    dispatch(
      updateFieldThunkFunction({
        ...field,
        configStr: JSON.stringify(updatedConfig.toJson()),
      })
    );
  };

  return (
    <table>
      <tbody
        style={{
          height: "100%",
        }}
      >
        <tr>
          <td>
            <label htmlFor="min-length">Min length:</label>
          </td>
          <td>
            <input
              type="number"
              id="min-length"
              value={minLength}
              onChange={(e) => setMinLength(parseInt(e.target.value))}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="max-length">Max length:</label>
          </td>
          <td>
            <input
              type="number"
              id="max-length"
              value={maxLength}
              onChange={(e) => setMaxLength(parseInt(e.target.value))}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="allowed-characters">Allowed characters:</label>
          </td>
          <td>
            <input
              type="text"
              id="allowed-characters"
              value={allowedCharacters.join("")}
              placeholder="All characters allowed"
              onChange={(e) => setAllowedCharacters(e.target.value.split(""))}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="disallowed-characters">
              Disallowed characters:
            </label>
          </td>
          <td>
            <input
              type="text"
              id="disallowed-characters"
              placeholder="No characters disallowed"
              value={disallowedCharacters.join("")}
              onChange={(e) =>
                setDisallowedCharacters(e.target.value.split(""))
              }
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="is-whitespace-allowed">
              Is Whitespace Allowed:
            </label>
          </td>
          <td>
            <input
              type="checkbox"
              id="is-whitespace-allowed"
              checked={isWhitespaceAllowed}
              onChange={(e) => setIsWhitespaceAllowed(e.target.checked)}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            {errors.map((error, idx) => (
              <p key={idx} style={{ color: "red" }}>
                {error.message}
              </p>
            ))}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <SaveButton onSave={handleSave} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
