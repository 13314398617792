import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../redux/store";

export enum ActionStatusType {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  PENDING = "PENDING",
}

export interface ActionStatus {
  type: ActionStatusType;
  key: string;
  message?: string;
}

const initialState: Array<ActionStatus> = [];

export const actionStatusSlice = createSlice({
  name: "actionStatus",
  initialState,
  reducers: {
    addPendingActionStatus: (state, action: PayloadAction<{ key: string }>) => {
      const tmpState = removeActionStatusByKey(state, action.payload.key);
      return [
        ...tmpState,
        {
          type: ActionStatusType.PENDING,
          key: action.payload.key,
        },
      ];
    },
    addSuccessfulActionStatus: (
      state,
      action: PayloadAction<{ key: string }>
    ) => {
      const tmpState = removeActionStatusByKey(state, action.payload.key);
      return [
        ...tmpState,
        {
          type: ActionStatusType.SUCCESS,
          key: action.payload.key,
        },
      ];
    },
    addFailedActionStatus: (
      state,
      action: PayloadAction<{ key: string; message?: string }>
    ) => {
      const tmpState = removeActionStatusByKey(state, action.payload.key);
      return [
        ...tmpState,
        {
          type: ActionStatusType.FAILURE,
          key: action.payload.key,
          message: action.payload.message,
        },
      ];
    },
    clearActionStatusByKey: (state, action: PayloadAction<{ key: string }>) => {
      return removeActionStatusByKey(state, action.payload.key);
    },
  },
});

const removeActionStatusByKey = (state: Array<ActionStatus>, key: string) =>
  state.filter((status) => status.key !== key);

export const {
  addPendingActionStatus,
  addSuccessfulActionStatus,
  addFailedActionStatus,
  clearActionStatusByKey,
} = actionStatusSlice.actions;

export const selectSuccessfulActionStatuses = (state: RootState) => {
  return state.actionStatus.filter(
    (status) => status.type === ActionStatusType.SUCCESS
  );
};

export const selectFailedActionStatuses = (state: RootState) => {
  return state.actionStatus.filter(
    (status) => status.type === ActionStatusType.FAILURE
  );
};

export const selectPendingActionStatuses = (state: RootState) => {
  return state.actionStatus.filter(
    (status) => status.type === ActionStatusType.PENDING
  );
};

export const selectStatusByEventKey = (
  state: RootState,
  key: string
): ActionStatus | null => {
  return state.actionStatus.find((status) => status.key === key) ?? null;
};

export default actionStatusSlice.reducer;
