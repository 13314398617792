import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { datroxPages } from "../dashboard/redux/routeSlice";
import styles from "./home.module.css";

interface BusinessBreadcrumbProps {
  businessName?: string;
}

const Breadcrumbs: React.FC<BusinessBreadcrumbProps> = ({ businessName }) => {
  return (
    <div className={styles.pageTitle}>
      <Breadcrumb>
        <Breadcrumb.Item
          href={datroxPages.home}
          className={styles.customBreadcrumbItem}
        >
          Businesses
        </Breadcrumb.Item>
        <Breadcrumb.Item active className={styles.customBreadcrumbItem}>
          {businessName}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
