import { TokenType, TokenTypes } from "./TokenType";
import { AbstractTokenConfig } from "./AbstractTokenConfig";
import { ValidationErrors } from "./ErrorMessage";

interface JsonConfigData {
  type: TokenType;
  maxLength: number;
}

export class JsonConfig extends AbstractTokenConfig {
  static __type: TokenType = TokenTypes.json as TokenType;
  static DEFAULT_MAX_LENGTH = 1000000; // 1MB

  public constructor(public maxLength: number = JsonConfig.DEFAULT_MAX_LENGTH) {
    super(JsonConfig.__type as TokenType);
  }

  public static fromJson(data: JsonConfigData): JsonConfig {
    return new JsonConfig(data.maxLength);
  }

  public toJson(): JsonConfigData {
    return {
      type: JsonConfig.__type,
      maxLength: this.maxLength,
    };
  }

  public copyWith(maxLength: number | undefined): JsonConfig {
    return new JsonConfig(maxLength === undefined ? this.maxLength : maxLength);
  }

  public validateRules(): Array<ValidationErrors> {
    if (this.maxLength <= 0) {
      return [
        {
          isValid: false,
          message: "Max length must be greater than 0",
        },
      ];
    }
    return [];
  }

  public validateInput(input: string): Array<ValidationErrors> {
    if (input === "" && this.isRequired) {
      return [{ isValid: false, message: "Input is required" }];
    }
    if (input.length > this.maxLength) {
      return [
        {
          isValid: false,
          message: `Input length must be less than ${this.maxLength}`,
        },
      ];
    }
    try {
      const parsed = JSON.parse(input);
      if (typeof parsed !== "object") {
        return [{ isValid: false, message: "Input must be a JSON object" }];
      }
    } catch (e) {
      return [{ isValid: false, message: "Invalid JSON" }];
    }
    return [];
  }
}
