import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "./redux/authSlice";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AuthContaienr = () => {
  const user = useAppSelector(selectUser);
  if (user) {
    return <SignedInRedirect />;
  } else {
    return <Outlet />;
  }
};

const SignedInRedirect = (props: {}) => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/user/home");
    }
  }, [user, navigate]);
  return null;
};

export { AuthContaienr };
