import { useAppDispatch } from "../../redux/hooks";
import { showEditorModalThunk } from "./redux/inputModalSlice";

export const RichTextCell = (props: {
  datasetUuid: string;
  row: number;
  fieldUuid: string;
  isActive: boolean;
}): React.ReactElement => {
  const appDispatch = useAppDispatch();
  return (
    <button
      onClick={() => {
        appDispatch(
          showEditorModalThunk({
            datasetUuid: props.datasetUuid,
            row: props.row,
            fieldUuid: props.fieldUuid,
            shouldShowModal: true,
          })
        );
      }}
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: props.isActive ? "lightblue" : "white",
      }}
    >
      Rich Text
    </button>
  );
};
