import {
  FieldType,
  FieldTypes,
} from "../../pages/fieldMetadata/redux/fieldSlice";
import { DigitTokenConfig } from "./DigitTokenConfig";
import { LetterTokenConfig } from "./LetterTokenConfig";
import { JsonConfig } from "./JsonConfig";
import { EmailConfig } from "./EmailConfig";
import { DateConfig } from "./DateConfig";
import { UrlConfig } from "./UrlConfig";
import { RichTextConfig } from "./RichTextConfig";
import { ListConfig } from "./ListConfig";

type RuleConfigType =
  | DigitTokenConfig
  | LetterTokenConfig
  | JsonConfig
  | EmailConfig
  | DateConfig
  | UrlConfig
  | ListConfig;

class RuleConfigFactory {
  private configCreators: Record<FieldType, (data: any) => RuleConfigType> = {};

  registerConfigType(type: FieldType, creator: (data: any) => RuleConfigType) {
    this.configCreators[type] = creator;
  }

  createConfig(type: FieldType, parsedRule: any): RuleConfigType {
    const creator = this.configCreators[type];
    if (!creator) {
      throw new Error("Invalid field type - " + type + " - for rule config.");
    }
    return creator(parsedRule);
  }
}

const ruleConfigFactory = new RuleConfigFactory();
ruleConfigFactory.registerConfigType(FieldTypes.number, (data) =>
  DigitTokenConfig.fromJson(data)
);
ruleConfigFactory.registerConfigType(FieldTypes.text, (data) =>
  LetterTokenConfig.fromJson(data)
);
ruleConfigFactory.registerConfigType(FieldTypes.json, (data) =>
  JsonConfig.fromJson(data)
);
ruleConfigFactory.registerConfigType(FieldTypes.email, (data) =>
  EmailConfig.fromJson(data)
);
ruleConfigFactory.registerConfigType(FieldTypes.date, (data) =>
  DateConfig.fromJson(data)
);
ruleConfigFactory.registerConfigType(FieldTypes.url, (data) =>
  UrlConfig.fromJson(data)
);
ruleConfigFactory.registerConfigType(FieldTypes.rich_text, (data) =>
  RichTextConfig.fromJson(data)
);
ruleConfigFactory.registerConfigType(FieldTypes.list, (data) =>
  ListConfig.fromJson(data)
);

export { ruleConfigFactory };
