import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ValidationErrors } from "../../services/rules/ErrorMessage";
import { UrlConfig } from "../../services/rules/UrlConfig";
import {
  selectField,
  updateFieldThunkFunction,
} from "../fieldMetadata/redux/fieldSlice";
import { RuleEditorProps } from "./ruleEditorPropsInterface";

export const RichTextRuleEditor = (props: RuleEditorProps) => {
  const [maxLength, setMaxLength] = useState<number>(
    UrlConfig.DEFAULT_MAX_LENGTH
  );
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationErrors[]>([]);

  const field = useAppSelector((state) => selectField(state, props.fieldUuid));

  const rule = field?.configStr ?? null;

  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (!rule) {
      return;
    }
    const parsedRule = JSON.parse(rule);
    const config = UrlConfig.fromJson(parsedRule);
    setMaxLength(config.maxLength);
    setIsRequired(config.isRequired);
  }, [rule]);

  const handleSave = () => {
    if (field == null) {
      return;
    }
    const updatedConfig = new UrlConfig(maxLength, isRequired);
    try {
      const errors: Array<ValidationErrors> = updatedConfig.validateRules();
      if (errors.length > 0) {
        setErrors(errors);
        return;
      } else {
        setErrors([]);
      }
    } catch (e: any) {
      alert(`Failed to validate rules: ${e.message}`);
      return;
    }

    appDispatch(
      updateFieldThunkFunction({
        ...field,
        configStr: JSON.stringify(updatedConfig.toJson()),
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        width: "100%",
        height: "100%",
        backgroundColor: "lightgrey",
      }}
    >
      <table>
        <tbody>
          <tr>
            <td>
              <label htmlFor="max-length">Max Length:</label>
            </td>
            <td>
              <input
                type="number"
                id="max-length"
                value={maxLength}
                onChange={(e) => setMaxLength(parseInt(e.target.value))}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="is-required">Is Required:</label>
            </td>
            <td>
              <input
                type="checkbox"
                id="is-required"
                checked={isRequired}
                onChange={(e) => setIsRequired(e.target.checked)}
              />
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              {errors.map((error) => (
                <div
                  key={error.message}
                  style={{
                    color: "red",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {error.message}
                </div>
              ))}
            </td>
          </tr>
        </tbody>
      </table>

      <button onClick={handleSave}>Save</button>
    </div>
  );
};
