import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ValidationErrors } from "../../services/rules/ErrorMessage";
import {
  selectField,
  updateFieldThunkFunction,
} from "../fieldMetadata/redux/fieldSlice";
import { JsonConfig } from "../../services/rules/JsonConfig";
import { SaveButton } from "./components/SaveButton";
import { RuleEditorProps } from "./ruleEditorPropsInterface";


export const JsonRuleEditor = (props: RuleEditorProps) => {
  const [maxLength, setMaxLength] = useState<number>(
    JsonConfig.DEFAULT_MAX_LENGTH
  );
  const [errors, setErrors] = useState<ValidationErrors[]>([]);
  const field = useAppSelector((state) => selectField(state, props.fieldUuid));
  const rule = field?.configStr ?? null;

  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (!rule) {
      return;
    }
    const parsedRule = JSON.parse(rule);
    const config = JsonConfig.fromJson(parsedRule);
    setMaxLength(config.maxLength);
  }, [rule]);

  const handleSave = () => {
    if (field == null) {
      return;
    }
    const updatedConfig = new JsonConfig(maxLength);
    try {
      const errors: Array<ValidationErrors> = updatedConfig.validateRules();
      if (errors.length > 0) {
        setErrors(errors);
        return;
      } else {
        setErrors([]);
      }
    } catch (e: any) {
      alert(`Failed to validate rules: ${e.message}`);
      return;
    }

    appDispatch(
      updateFieldThunkFunction({
        ...field,
        configStr: JSON.stringify(updatedConfig.toJson()),
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        width: "100%",
        height: "100%",
        backgroundColor: "lightgrey",
      }}
    >
      <table>
        <tr>
          <td>
            <label htmlFor="min-value">Max Length:</label>
          </td>
          <td>
            <input
              type="number"
              id="min-value"
              value={maxLength}
              onChange={(e) => setMaxLength(parseInt(e.target.value))}
            />
          </td>
        </tr>

        <tr>
          <td colSpan={2}>
            {errors.map((error) => (
              <div
                key={error.message}
                style={{
                  color: "red",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {error.message}
              </div>
            ))}
          </td>
        </tr>
      </table>

      <SaveButton onSave={handleSave} />
    </div>
  );
};
