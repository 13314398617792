import { TokenType, TokenTypes } from "./TokenType";
import { AbstractTokenConfig } from "./AbstractTokenConfig";
import { ValidationErrors } from "./ErrorMessage";

interface LetterTokenConfigData {
  type: TokenType;
  minLength: number;
  maxLength: number;
  allowedCharacters?: string[]; // Optional
  disallowedCharacters?: string[]; // Optional
  isDigitsAllowed: boolean;
  isWhitespaceAllowed: boolean;
}

export class LetterTokenConfig extends AbstractTokenConfig {
  static __type: TokenType = TokenTypes.letter as TokenType;
  static DEFAULT_MIN_LENGTH = 0;
  static DEFAULT_MAX_LENGTH = 1000;

  public minLength: number;
  public maxLength: number;
  public allowedCharacters: string[];
  public disallowedCharacters: string[];
  public isDigitsAllowed: boolean;
  public isWhitespaceAllowed: boolean;

  public constructor(
    minLength: number = LetterTokenConfig.DEFAULT_MIN_LENGTH,
    maxLength: number = LetterTokenConfig.DEFAULT_MAX_LENGTH,
    allowedCharacters: string[] = [],
    disallowedCharacters: string[] = [],
    isWhitespaceAllowed: boolean = false,
    isDigitsAllowed: boolean = true
  ) {
    super(LetterTokenConfig.__type as TokenType);
    this.minLength = minLength;
    this.maxLength = maxLength;
    this.allowedCharacters = allowedCharacters;
    this.disallowedCharacters = disallowedCharacters;
    this.isWhitespaceAllowed = isWhitespaceAllowed;
    this.isDigitsAllowed = isDigitsAllowed;
  }

  public static fromJson(data: LetterTokenConfigData): LetterTokenConfig {
    return new LetterTokenConfig(
      data.minLength,
      data.maxLength,
      data.allowedCharacters || [],
      data.disallowedCharacters || [],
      data.isWhitespaceAllowed || false,
      data.isDigitsAllowed || true
    );
  }

  public toJson(): LetterTokenConfigData {
    return {
      type: LetterTokenConfig.__type,
      minLength: this.minLength,
      maxLength: this.maxLength,
      allowedCharacters: this.allowedCharacters,
      disallowedCharacters: this.disallowedCharacters,
      isWhitespaceAllowed: this.isWhitespaceAllowed,
      isDigitsAllowed: this.isDigitsAllowed,
    };
  }

  public copyWith(
    minLength: number | undefined,
    maxLength: number | undefined,
    allowedCharacters: string[] | undefined,
    disallowedCharacters: string[] | undefined,
    isWhitespaceAllowed: boolean | undefined,
    isDigitsAllowed: boolean | undefined
  ): LetterTokenConfig {
    return new LetterTokenConfig(
      minLength === undefined ? this.minLength : minLength,
      maxLength === undefined ? this.maxLength : maxLength,
      allowedCharacters === undefined
        ? this.allowedCharacters
        : allowedCharacters,
      disallowedCharacters === undefined
        ? this.disallowedCharacters
        : disallowedCharacters,
      isDigitsAllowed === undefined ? this.isDigitsAllowed : isDigitsAllowed,
      isWhitespaceAllowed === undefined
        ? this.isWhitespaceAllowed
        : isWhitespaceAllowed
    );
  }

  public validateRules(): Array<ValidationErrors> {
    if (this.minLength < 0) {
      return [{ isValid: false, message: "Min length cannot be negative" }];
    }
    if (this.maxLength < 0) {
      return [{ isValid: false, message: "Max length cannot be negative" }];
    }
    if (this.minLength > this.maxLength) {
      return [
        {
          isValid: false,
          message: "Min length cannot be greater than max length",
        },
      ];
    }
    if (this.minLength === 0 && this.isRequired) {
      return [
        {
          isValid: false,
          message: "Min length cannot be 0 when field is required",
        },
      ];
    }
    // check if there's an overlap between allowed and disallowed characters
    if (this.allowedCharacters && this.disallowedCharacters) {
      const overlappedCharacters = this.allowedCharacters.filter((char) =>
        this.disallowedCharacters.includes(char)
      );
      if (overlappedCharacters.length > 0) {
        return [
          {
            isValid: false,
            message: `Allowed and disallowed characters should not overlap - ${overlappedCharacters.join(
              ", "
            )}`,
          },
        ];
      }
    }
    if (!this.isWhitespaceAllowed && this.allowedCharacters.includes(" ")) {
      return [
        {
          isValid: false,
          message:
            "Whitespace is not allowed yet is included in 'Allowed Characters' list",
        },
      ];
    }

    return [];
  }

  public validateInput(input: string): Array<ValidationErrors> {
    if (input.length < this.minLength) {
      return [
        {
          isValid: false,
          message: `Input length should be greater than ${this.minLength}`,
        },
      ];
    }
    if (input.length > this.maxLength) {
      return [
        {
          isValid: false,
          message: `Input length should be less than ${this.maxLength}`,
        },
      ];
    }
    if (!this.isDigitsAllowed && /\d/.test(input)) {
      return [{ isValid: false, message: "Digits are not allowed" }];
    }
    if (!this.isWhitespaceAllowed && /\s/.test(input)) {
      return [{ isValid: false, message: "Whitespace is not allowed" }];
    }
    if (
      this.allowedCharacters.length > 0 &&
      input.split("").some((char) => !this.allowedCharacters.includes(char))
    ) {
      return [
        {
          isValid: false,
          message: `Input should contain only allowed characters - ${this.allowedCharacters.join(
            ", "
          )}`,
        },
      ];
    }
    if (
      this.disallowedCharacters.length > 0 &&
      this.disallowedCharacters.some((char) => input.includes(char))
    ) {
      return [
        {
          isValid: false,
          message: `Input should not contain disallowed characters - ${this.disallowedCharacters.join(
            ", "
          )}`,
        },
      ];
    }
    return [];
  }
}
