import React from "react";

import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  ActionToActionStatusKey,
  createBusinessThunk,
} from "./redux/businessSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  ActionStatusType,
  clearActionStatusByKey,
  selectStatusByEventKey,
} from "../../pages/actions/redux/actionSlice";

export const BusinessCreationModal = (props: {
  show: boolean;
  onHide: () => void;
}) => {
  const [businessName, setBusinessName] = useState("");
  const appDispatch = useAppDispatch();
  const EVENT_KEY = ActionToActionStatusKey.CREATE_BUSINESS;

  const status = useAppSelector((state) =>
    selectStatusByEventKey(state, EVENT_KEY)
  );

  const handleClose = () => {
    appDispatch(clearActionStatusByKey({ key: EVENT_KEY }));
    setBusinessName("");
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      onShow={() => {
        appDispatch(clearActionStatusByKey({ key: EVENT_KEY }));
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a new business</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          placeholder="Business name"
          onChange={(e) => {
            setBusinessName(e.target.value);
          }}
        />
        {status?.type === ActionStatusType.FAILURE ? (
          <div style={{ color: "red" }}>{status.message}</div>
        ) : null}
        {status?.type === ActionStatusType.SUCCESS ? (
          <div style={{ color: "green" }}>Business created successfully</div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={status?.type === ActionStatusType.PENDING}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant="primary"
          disabled={businessName === "" || status?.type != null}
          onClick={() => {
            if (businessName !== "") {
              appDispatch(createBusinessThunk(businessName));
            }
          }}
        >
          {status?.type === ActionStatusType.PENDING ? (
            <Spinner size="sm" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : null}
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
