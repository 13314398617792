import { fetchAllFieldsThunkFunction } from "../fieldMetadata/redux/fieldSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useParams } from "react-router-dom";
import { initActiveCellForDataset } from "./redux/activeCellSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { SocketService } from "../../events/socketService";
import {
  initDatasetThunkFunction,
  updateDataBatchThunk,
} from "./redux/dataSlice";
import { DatasetTableContainer } from "./DatasetTableContainer";
import { RuleEditorContainer } from "./editor/RuleEditorContainer";
import { CellEditorContainer } from "./editor/CellEditorContainer";
import { DatasetService } from "./services/dataset.service";
import type { Socket } from "socket.io-client";

export const DatasetHome = () => {
  const param = useParams<{ uuid: string }>();
  const datasetUuid = param.uuid as string;

  const initActiveCell = useAppSelector((state) => state.activeCell);
  const dispatch = useDispatch();
  let socket: Socket | null = SocketService.maybeGetSocket();
  if (!socket) {
    SocketService.genGetSocketService().then((socketService) => {
      socket = socketService.socket;
    });
  }
  useEffect(() => {
    dispatch(initDatasetThunkFunction(datasetUuid));
    dispatch(fetchAllFieldsThunkFunction(datasetUuid));
    if (socket) {
      console.log("Joining room:", datasetUuid);
      socket.emit(DatasetService.JOIN_ROOM_EVENT, { datasetUuid });
    } else {
      console.log("Socket not initialized");
    }
  }, [dispatch, datasetUuid, socket]);

  if (initActiveCell.cell.datasetUuid !== datasetUuid) {
    dispatch(initActiveCellForDataset(datasetUuid));
  }

  const appDispatch = useAppDispatch();
  useEffect(() => {
    if (socket == null) {
      return;
    }
    const DATA_CHANGED_FROM_SERVER_EVENT = "data_changed_from_server";
    socket.on(DATA_CHANGED_FROM_SERVER_EVENT, (data: any) => {
      console.log("Received data change event", data);
      appDispatch(updateDataBatchThunk(data));
    });

    return () => {
      if (socket) {
        socket.off(DATA_CHANGED_FROM_SERVER_EVENT);
      }
    };
  }, [appDispatch, socket]);

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <DatasetTableContainer datasetUuid={datasetUuid} />
      </div>
      <RuleEditorContainer />
      <CellEditorContainer />
    </div>
  );
};
