import React from "react";
import { NavLink, Navigate } from "react-router-dom";
import { Row, Col, Table, Breadcrumb } from "react-bootstrap";
import {
  selectBusinesses,
  deleteBusinessThunk,
  fetchBusinessesForUserThunk,
  updateBusinessThunk,
  ActionToActionStatusKey,
} from "./redux/businessSlice";
import {
  DatroxPage,
  datroxPages,
  selectActivePath,
  setActiveRoute,
} from "../dashboard/redux/routeSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

import { handleSignOutThunk } from "../auth/redux/authSlice";
import { useDispatch } from "react-redux";
import { selectUser } from "../auth/redux/authSlice";
import { Business } from "./business.model";
import { Button, Container, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { BusinessCreationModal } from "./BusinessCreationModal";
import {
  ActionStatusType,
  selectStatusByEventKey,
} from "../actions/redux/actionSlice";
import "./Home.css";
import { DeletionModal } from "../components/DeletionModal";
import { EditModal } from "../components/EditModal";
import styles from "./home.module.css";

export const Home = (): React.ReactElement => {
  const [showCreateBusinessModal, setShowCreateBusinessModal] =
    useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [businessToDelete, setBusinessToDelete] = useState<Business | null>(
    null
  );
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [businessToEdit, setBusinessToEdit] = useState<Business | null>(null);

  const businesses = useAppSelector((state) => selectBusinesses(state));
  const user = useAppSelector(selectUser);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const loadingStatus = useAppSelector((state) =>
    selectStatusByEventKey(state, ActionToActionStatusKey.FETCH_BUSINESSES)
  );

  useEffect(() => {
    if (user !== null) {
      try {
        const path = window.location.pathname.split("/").pop();
        appDispatch(setActiveRoute(path as DatroxPage));
        appDispatch(fetchBusinessesForUserThunk());
      } catch (e) {
        console.log(e);
      }
    }
  }, [user, appDispatch]);

  if (user === null) {
    return <Navigate to="/auth/signin" replace={true} />;
  }

  const handleDeleteConfirm = () => {
    if (businessToDelete) {
      dispatch(deleteBusinessThunk(businessToDelete.uuid));
    }
    setShowDeleteConfirmation(false);
    setBusinessToDelete(null);
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
    setBusinessToDelete(null);
  };

  const handleEditSave = (newName: string) => {
    if (businessToEdit) {
      dispatch(
        updateBusinessThunk({
          uuid: businessToEdit.uuid,
          name: newName,
        })
      );
    }
    setShowEditModal(false);
    setBusinessToEdit(null);
  };

  const handleEditCancel = () => {
    setShowEditModal(false);
    setBusinessToEdit(null);
  };

  return (
    <div>
      <div className={styles.pageTitle}>
        <Breadcrumb>
          <Breadcrumb.Item
            href={datroxPages.home}
            className={styles.customBreadcrumbItem}
          >
            User
          </Breadcrumb.Item>
          <Breadcrumb.Item active className={styles.customBreadcrumbItem}>
            User Dashboard
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={styles.homePage}>
        <Row>
          <Col md={12}>
            <div
              className=" h-100 "
              style={{
                border: "1px solid #E2E8F0",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.5rem",
                padding: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#1A202C",
                    marginBottom: "1rem",
                  }}
                >
                  Businesses
                </h2>
                <Button
                  variant="primary"
                  style={{
                    marginLeft: "100px",
                    marginRight: "20px",
                  }}
                  onClick={() => {
                    setShowCreateBusinessModal(true);
                  }}
                >
                  Create a Business
                </Button>
              </div>
              {loadingStatus?.type === ActionStatusType.PENDING ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Spinner animation="border" />
                </div>
              ) : loadingStatus?.type === ActionStatusType.FAILURE ? (
                <div
                  style={{
                    color: "red",
                  }}
                >
                  Failed to load businesses. Try again later.
                </div>
              ) : businesses.length === 0 ? (
                <div>No businesses found</div>
              ) : (
                <BusinessesTable
                  businesses={businesses.map((business) => ({
                    uuid: business.uuid,
                    name: business.name,
                  }))}
                  onDelete={(business) => {
                    setBusinessToDelete(business);
                    setShowDeleteConfirmation(true);
                  }}
                  onEdit={(business) => {
                    setBusinessToEdit(business);
                    setShowEditModal(true);
                  }}
                />
              )}
              <BusinessCreationModal
                show={showCreateBusinessModal}
                onHide={() => {
                  setShowCreateBusinessModal(false);
                }}
              />
              <DeletionModal
                show={showDeleteConfirmation}
                onHide={handleDeleteCancel}
                deletionMessage={`Are you sure you want to delete the business "${businessToDelete?.name}"`}
                onDelete={handleDeleteConfirm}
              />
              <EditModal
                show={showEditModal}
                onHide={handleEditCancel}
                initialValue={businessToEdit?.name ?? ""}
                onSave={handleEditSave}
                editMessage="Enter a new name"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const BusinessesTable = (props: {
  businesses: Array<Business>;
  onDelete: (business: Business) => void;
  onEdit: (business: Business) => void;
}) => {
  return (
    <Table responsive className="table table-hover">
      <thead>
        <tr>
          <th>Business Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.businesses.map((business) => (
          <tr key={business.uuid}>
            <td>
              <NavLink to={`/business/${business.uuid}`}>
                {business.name}
              </NavLink>
            </td>
            <td className=" d-flex  align-items-center ">
              <Button
                variant="outline-success"
                onClick={() => {
                  props.onEdit(business);
                }}
              >
                Edit
              </Button>
              <Button
                className=" mx-3 "
                variant="outline-danger"
                onClick={() => {
                  props.onDelete(business);
                }}
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
