import { User } from "firebase/auth";

import { getCurUser } from "../pages/auth/services/authServices";
import assert from "assert";

export interface TCustomHeader {
  Authorization: string;
  "Content-Type": string;
}

export class BaseService {
  static API_HOST_URL =
    process.env.API_HOST_URL ?? "https://datrox.ue.r.appspot.com";

  public genGetIdToken = async (): Promise<string | null> => {
    const user = await getCurUser();
    return (await user?.getIdToken()) ?? null;
  };

  private getIsAuthenticated = async (): Promise<boolean> => {
    const user: User | null = await getCurUser();
    return user !== null && user.emailVerified && user.uid !== null;
  };

  private getAuthenticatedHeader = async (): Promise<TCustomHeader> => {
    const idToken = await this.genGetIdToken();
    return {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    };
  };

  protected genAuthenticatedPostX = async (
    url: string,
    body: Object
  ): Promise<any> => {
    if (!this.getIsAuthenticated()) {
      throw new Error("User is not authenticated");
    }
    const resp: Response = await fetch(`${BaseService.API_HOST_URL}${url}`, {
      method: "POST",
      body: JSON.stringify({
        ...body,
      }),
      headers: { ...(await this.getAuthenticatedHeader()) },
    });
    if (!resp.ok) {
      const respJson = await resp.json();
      throw new Error(respJson.message ?? "Failed to post data");
    }
    return await resp.json();
  };

  public genAuthenticatedDeleteX = async (url: string): Promise<void> => {
    const user = await getCurUser();
    if (!this.getIsAuthenticated() || !user?.uid) {
      throw new Error("User is not authenticated");
    }
    const resp = await fetch(`${BaseService.API_HOST_URL}${url}`, {
      method: "DELETE",
      headers: { ...(await this.getAuthenticatedHeader()) },
    });
    if (!resp.ok) {
      throw new Error("Failed to delete data");
    }
  };

  public genAuthenticatedGetX = async (url: string): Promise<any> => {
    assert(url.startsWith("/"), "URL must start with /");

    const user = await getCurUser();
    if (!this.getIsAuthenticated() || !user?.uid) {
      throw new Error("User is not authenticated");
    }
    const resp = await fetch(`${BaseService.API_HOST_URL}${url}`, {
      method: "GET",
      headers: { ...(await this.getAuthenticatedHeader()) },
    });
    if (!resp.ok) {
      throw new Error("Failed to get data");
    }
    return await resp.json();
  };

  public genAuthenticatedPatchX = async (
    url: string,
    body: Object
  ): Promise<void> => {
    const user = await getCurUser();
    if (!this.getIsAuthenticated() || !user?.uid) {
      throw new Error("User is not authenticated");
    }
    const resp = await fetch(`${BaseService.API_HOST_URL}${url}`, {
      method: "PATCH",
      body: JSON.stringify({
        ...body,
      }),
      headers: { ...(await this.getAuthenticatedHeader()) },
    });
    if (!resp.ok) {
      throw new Error("Failed to update data");
    }
  };
}
