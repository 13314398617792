import React from "react";

import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  ActionStatusType,
  clearActionStatusByKey,
  selectStatusByEventKey,
} from "../../../pages/actions/redux/actionSlice";
import {
  BusinessActionStatusKey,
  BusinessUser,
  selectBusinessUser,
  updateBusinessUserRoleThunk,
} from "../redux/businessUserSlice";
import {
  BusinessRole,
  BusinessRoleToString,
  BusinessRoles,
} from "../business.model";

export const RoleEditModal = (props: {
  show: boolean;
  onHide: () => void;
  defaultBusinessUser: BusinessUser;
}) => {
  const [role, setRole] = useState<BusinessRole>(
    props.defaultBusinessUser.role
  );
  const appDispatch = useAppDispatch();
  const EVENT_KEY = BusinessActionStatusKey.UPDATE_BUSINESS_USER_ROLE;
  const businessUser = useAppSelector((state) =>
    selectBusinessUser(
      state,
      props.defaultBusinessUser.businessUuid,
      props.defaultBusinessUser.userUuid
    )
  );

  const status = useAppSelector((state) =>
    selectStatusByEventKey(state, EVENT_KEY)
  );

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRole(parseInt(event.target.value) as BusinessRole);
    appDispatch(clearActionStatusByKey({ key: EVENT_KEY }));
  };

  const handleClose = () => {
    appDispatch(clearActionStatusByKey({ key: EVENT_KEY }));
    props.onHide();
  };

  if (businessUser === null) {
    return null;
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      onShow={() => {
        appDispatch(clearActionStatusByKey({ key: EVENT_KEY }));
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit User's Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input type="text" value={businessUser!.displayName} disabled />
          <div
            style={{
              height: 12,
            }}
          />
          <select onChange={handleRoleChange} defaultValue={role}>
            {BusinessRoles.map((role) => (
              <option value={role}>{BusinessRoleToString(role)}</option>
            ))}
          </select>
          <div>
            {status?.type === ActionStatusType.FAILURE ? (
              <div style={{ color: "red" }}>{status.message}</div>
            ) : null}
            {status?.type === ActionStatusType.SUCCESS ? (
              <div style={{ color: "green" }}>
                User's role updated successfully
              </div>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={status?.type === ActionStatusType.PENDING}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant="primary"
          disabled={role === businessUser!.role}
          onClick={() => {
            if (role !== businessUser!.role) {
              appDispatch(
                updateBusinessUserRoleThunk(
                  businessUser!.businessUuid,
                  businessUser!.userUuid,
                  role
                )
              );
            }
          }}
        >
          {status?.type === ActionStatusType.PENDING ? (
            <Spinner size="sm" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : null}
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
