import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";

export const datasetSettingsSlice = createSlice({
  name: "datasetSettings",
  initialState: {
    isRuleEditorOpen: false,
    isCellEditorOpen: false,
  },
  reducers: {
    toggleRuleEditor: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.isCellEditorOpen = false;
      }
      state.isRuleEditorOpen = action.payload;
    },
    toggleCellEditor: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.isRuleEditorOpen = false;
      }
      state.isCellEditorOpen = action.payload;
    },
  },
});

export const { toggleRuleEditor, toggleCellEditor } =
  datasetSettingsSlice.actions;

export const selectDatasetSettings = (state: RootState) =>
  state.datasetSettings;

export const selectIsCellEditorOpen = (state: RootState) =>
  state.datasetSettings.isCellEditorOpen;
