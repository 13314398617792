import { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { handlSignInThunk } from "./redux/authSlice";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import "./SignIn.css";
const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  return (
    <div className="signIn-page">
      <h1>Sign In</h1>
      <Form>
        <Form.Group>
          <Form.Control
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-3"
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mb-3"
            style={{ width: "400px" }}
          />
        </Form.Group>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(handlSignInThunk(email, password, setErrorMessage));
            }}
          >
            Sign In
          </Button>
        </div>
        <div style={{ textAlign: "center" }}>
          {errorMessage && (
            <Alert variant="danger" className="mt-3">
              {errorMessage}
            </Alert>
          )}
          <NavLink to="/auth/forgot-password" className="mt-3 d-block">
            Forgot Password?
          </NavLink>
          <p className="mt-3">
            Don't have an account? <a href="/auth/signUp">Sign Up</a>
          </p>
        </div>
      </Form>
    </div>
  );
};

export { SignIn };
