import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectField, selectFieldsByDatasetUuid } from "./redux/fieldSlice";
import { useDispatch } from "react-redux";

import styles from "../dataset/DatasetHome.module.css";

import {
  FieldTypes,
  addFieldThunkFunction,
  updateFieldThunkFunction,
} from "./redux/fieldSlice";
import { v4 as uuidV4 } from "uuid";
import { setActiveCell } from "../../pages/dataset/redux/activeCellSlice";
import { toggleRuleEditor } from "../../pages/dataset/redux/detasetSettingsSlice";
import { Button } from "react-bootstrap";

interface FieldsRowProps {
  datasetUuid: string;
}

export const FieldsRow = (props: FieldsRowProps): React.ReactElement => {
  const fields = useAppSelector((state) =>
    selectFieldsByDatasetUuid(state, props.datasetUuid)
  );

  const activeCell = useAppSelector((state) => state.activeCell.cell);
  if (fields.length === 0) {
    return <></>;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        height: "40px",
      }}
    >
      <div className={`${styles.datasetIndexCell} `} key={`fields-cell`}>
        <h4>#</h4>
      </div>
      {fields.map((field: any) => {
        return (
          <div
            className={styles.datasetHeaderCell}
            key={`div-${field.uuid}`}
            style={{
              border: "0.5px solid black",
              borderBottom:
                activeCell?.fieldUuid === field.uuid
                  ? "6px solid blue"
                  : "6px solid grey",
              height: "40px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FieldName uuid={field.uuid} />
          </div>
        );
      })}
      <div
        className={`${styles.datasetHeaderCell} ${styles.datasetIndexCell}`}
        style={{
          width: "100px",
        }}
      >
        <AddFieldButton datasetUuid={props.datasetUuid} />
      </div>
    </div>
  );
};

export const AddFieldButton = (props: {
  datasetUuid: string;
}): React.ReactElement => {
  const dispatch = useDispatch();
  return (
    <Button
      className={styles.CreateNewFieldBTN}
      onClick={() => {
        const newUuid = uuidV4();
        dispatch(
          addFieldThunkFunction(
            newUuid,
            props.datasetUuid,
            FieldTypes.text,
            "New Field"
          )
        );
        dispatch(
          setActiveCell({
            datasetUuid: props.datasetUuid,
            row: 0,
            fieldUuid: newUuid,
          })
        );
      }}
    >
      + Add New Field
    </Button>
  );
};

interface FieldNameProps {
  uuid: string;
}

const FieldName = (props: FieldNameProps): React.ReactElement => {
  const field = useAppSelector((state) => selectField(state, props.uuid));
  const activeCell = useAppSelector((state) => state.activeCell.cell);
  const dispatch = useAppDispatch();

  const isActive = activeCell?.fieldUuid === props.uuid;
  if (!field) {
    return <div></div>;
  }
  return (
    <input
      type="text"
      placeholder={field.name}
      value={field.name}
      style={{
        width: "100%",
        fontSize: "16px",
        textAlign: "center",
        height: "100%",

        border: 0,
        backgroundColor: isActive ? "lightblue" : "white",
      }}
      onChange={(e) => {
        dispatch(
          updateFieldThunkFunction({
            ...field,
            name: e.target.value,
          })
        );
      }}
      onClick={(_e) => {
        dispatch(toggleRuleEditor(true));
        dispatch(
          setActiveCell({
            datasetUuid: field.datasetUuid,
            row: 0,
            fieldUuid: field.uuid,
          })
        );
      }}
    />
  );
};
