import { collection, addDoc } from "firebase/firestore";
import { firestoreDb } from "services/firebaseAppService";

export const handleJoinWaitlist = async (email: string): Promise<boolean> => {
  const waitlistRef = collection(firestoreDb, "waitlist");
  try {
    await addDoc(waitlistRef, { email });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};
