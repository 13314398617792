import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Breadcrumb, Col, Row, Table } from "react-bootstrap";
import {
  ActionToActionStatusKey,
  fetchAllDatasetsByBusinessUuidThunk,
  selectBusinessByUuid,
} from "./redux/businessSlice";
import {
  Dataset,
  createDatasetThunk,
  deleteDatasetThunk,
  selectDatasetsByBusinessUuid,
  updateDatasetThunk,
} from "../dataset/redux/datasetSlice";
import {
  DatroxPage,
  datroxPages,
  selectActivePath,
  setActiveRoute,
} from "../dashboard/redux/routeSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useDispatch } from "react-redux";
import {
  ActionStatusType,
  selectStatusByEventKey,
} from "../actions/redux/actionSlice";
import { Button, Spinner } from "react-bootstrap";
import { BusinessUserRoles } from "./businessUser/BusinessUserRoles";
import { fetchUsersForBusinessThunk } from "./redux/businessUserSlice";
import { DeletionModal } from "../components/DeletionModal";
import { EditModal } from "../components/EditModal";
import { CreateDatasetModal } from "../components/CreateDatasetModal";
import NavBar from "../../pages/dashboard/NavBar";
import styles from "./home.module.css";
import Breadcrumbs from "./Breadcrumbs";
export const BusinessPage = (props: any) => {
  const params = useParams();
  const businessUuid = params.uuid;
  const EVENT_KEY = ActionToActionStatusKey.FETCH_DATASETS;

  const business = useAppSelector((state) =>
    selectBusinessByUuid(state, businessUuid!)
  );
  const datasets = useAppSelector((state) =>
    selectDatasetsByBusinessUuid(state, businessUuid!)
  );
  const actionStatus = useAppSelector((state) =>
    selectStatusByEventKey(state, EVENT_KEY)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (businessUuid) {
      dispatch(fetchAllDatasetsByBusinessUuidThunk(businessUuid));
      dispatch(fetchUsersForBusinessThunk(businessUuid));
    }
  }, [businessUuid, dispatch]);

  const [showCreateDatasetModal, setShowCreateDatasetModal] = useState(false);

  return (
    <div
      style={{
        width: "auto",
        height: "100vh",
        backgroundColor: "#E5E7EB",
        overflowX: "hidden",
      }}
    >
      <NavBar />
      <Breadcrumbs businessName={business?.name}/>
      <div className={styles.homePage}>
        <Row>
          <Col md={12}>
            <div
              className=" "
              style={{
                border: "1px solid #E2E8F0",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.5rem",
                padding: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#1A202C",
                    marginBottom: "1rem",
                  }}
                >
                  Datasets
                </h2>
                <Button
                  variant="primary"
                  style={{
                    marginLeft: "100px",
                    marginRight: "20px",
                  }}
                  onClick={() => setShowCreateDatasetModal(true)}
                >
                  Create a Dataset
                </Button>
              </div>
              <CreateDatasetModal
                show={showCreateDatasetModal}
                onHide={() => setShowCreateDatasetModal(false)}
                businessUuid={businessUuid || ""}
              />{" "}
              {}
              <div>
                {actionStatus?.type === ActionStatusType.PENDING ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : datasets.length === 0 ? (
                  <div>Business doesn't have a dataset yet</div>
                ) : (
                  <DatasetTable datasets={datasets} />
                )}
              </div>
            </div>
            <div
              className="mt-5"
              style={{
                border: "1px solid #E2E8F0",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.5rem",
                padding: "2rem",
              }}
            >
              <BusinessUserRoles businessUuid={businessUuid ?? ""} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const DatasetTable = (props: { datasets: Array<Dataset> }) => {
  const dispatch = useAppDispatch();

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [datasetToDelete, setDatasetToDelete] = useState<Dataset | null>(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [datasetToEdit, setDatasetToEdit] = useState<Dataset | null>(null);

  const handleDeleteConfirm = () => {
    if (datasetToDelete) {
      dispatch(deleteDatasetThunk(datasetToDelete.uuid));
    }
    setShowDeleteConfirmation(false);
    setDatasetToDelete(null);
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
    setDatasetToDelete(null);
  };

  const handleEditSave = (newName: string) => {
    if (datasetToEdit) {
      dispatch(updateDatasetThunk(datasetToEdit.uuid, newName));
    }
    setShowEditModal(false);
    setDatasetToEdit(null);
  };

  const handleEditCancel = () => {
    setShowEditModal(false);
    setDatasetToEdit(null);
  };

  return (
    <div>
      <Table responsive className="table table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.datasets.map((dataset) => (
            <tr key={dataset.uuid}>
              <td>
                <NavLink key={dataset.uuid} to={`/dataset/${dataset.uuid}`}>
                  {dataset.name}
                </NavLink>
              </td>
              <td>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setDatasetToEdit(dataset);
                    setShowEditModal(true);
                  }}
                >
                  <i className="bi bi-pencil-square" />
                  &nbsp; Edit
                </Button>
                <Button
                  variant="outline-danger"
                  style={{
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setDatasetToDelete(dataset);
                    setShowDeleteConfirmation(true);
                  }}
                >
                  <i className="bi bi-trash"></i>
                  &nbsp; Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <DeletionModal
        show={showDeleteConfirmation}
        onHide={handleDeleteCancel}
        deletionMessage={`Are you sure you want to delete the dataset "${datasetToDelete?.name}"`}
        onDelete={handleDeleteConfirm}
      />
      <EditModal
        show={showEditModal}
        onHide={handleEditCancel}
        initialValue={datasetToEdit?.name ?? ""}
        onSave={handleEditSave}
        editMessage="Enter a new name"
      />
    </div>
  );
};
