import { TokenType, TokenTypes } from "./TokenType";
import { AbstractTokenConfig } from "./AbstractTokenConfig";
import { ValidationErrors } from "./ErrorMessage";

interface EmailConfigData {
  type: TokenType;
  maxLength: number;
  isRequired: boolean;
}

export class EmailConfig extends AbstractTokenConfig {
  static __type: TokenType = TokenTypes.email as TokenType;
  static DEFAULT_MAX_LENGTH = 254;

  public constructor(
    public maxLength: number = EmailConfig.DEFAULT_MAX_LENGTH,
    public isRequired: boolean = false
  ) {
    super(EmailConfig.__type as TokenType);
  }

  public static fromJson(data: EmailConfigData): EmailConfig {
    return new EmailConfig(data.maxLength, data.isRequired);
  }

  public toJson(): EmailConfigData {
    return {
      type: EmailConfig.__type,
      maxLength: this.maxLength,
      isRequired: this.isRequired,
    };
  }

  public copyWith(
    maxLength: number | undefined,
    isRequired: boolean | undefined
  ): EmailConfig {
    return new EmailConfig(
      maxLength === undefined ? this.maxLength : maxLength,
      isRequired === undefined ? this.isRequired : isRequired
    );
  }

  public validateRules(): Array<ValidationErrors> {
    if (this.maxLength <= 0) {
      return [
        {
          isValid: false,
          message: "Max length must be greater than 0",
        },
      ];
    }
    return [];
  }

  public validateInput(input: string): Array<ValidationErrors> {
    if (input === "" && this.isRequired) {
      return [{ isValid: false, message: "Input is required" }];
    }
    if (input.length > this.maxLength) {
      return [
        {
          isValid: false,
          message: `Input length must be less than ${this.maxLength}`,
        },
      ];
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(input)) {
      return [{ isValid: false, message: "Invalid email format" }];
    }
    return [];
  }
}
