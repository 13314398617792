import { TokenType, TokenTypes } from "./TokenType";
import { AbstractTokenConfig } from "./AbstractTokenConfig";
import { ValidationErrors } from "./ErrorMessage";

interface DigitTokenConfigData {
  type: TokenType;
  minValue: number;
  maxValue: number;
  integerOnly: boolean;
  roundUpto: number;
}

export class DigitTokenConfig extends AbstractTokenConfig {
  static __type: TokenType = TokenTypes.digit as TokenType;
  public minValue: number;
  public maxValue: number;
  public integerOnly: boolean;
  public roundUpto: number;

  public constructor(
    minValue: number = 0,
    maxValue: number = 1000,
    integerOnly: boolean = false,
    roundUpto: number = 0
  ) {
    super(DigitTokenConfig.__type as TokenType);
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.integerOnly = integerOnly;
    this.roundUpto = roundUpto;
  }

  public static fromJson(data: DigitTokenConfigData): DigitTokenConfig {
    return new DigitTokenConfig(
      data.minValue,
      data.maxValue,
      data.integerOnly,
      data.roundUpto
    );
  }

  public toJson(): DigitTokenConfigData {
    return {
      type: DigitTokenConfig.__type,
      minValue: this.minValue,
      maxValue: this.maxValue,
      integerOnly: this.integerOnly,
      roundUpto: this.roundUpto,
    };
  }

  public copyWith(
    minValue: number | undefined,
    maxValue: number | undefined,
    integerOnly: boolean | undefined,
    roundUpto: number | undefined
  ): DigitTokenConfig {
    return new DigitTokenConfig(
      minValue === undefined ? this.minValue : minValue,
      maxValue === undefined ? this.maxValue : maxValue,
      integerOnly === undefined ? this.integerOnly : integerOnly,
      roundUpto === undefined ? this.roundUpto : roundUpto
    );
  }

  public validateRules(): Array<ValidationErrors> {
    if (this.minValue > this.maxValue) {
      return [
        {
          isValid: false,
          message: "Min value cannot be greater than max value",
        },
      ];
    }
    if (this.roundUpto < 0) {
      return [
        {
          isValid: false,
          message: "Round upto cannot be negative",
        },
      ];
    }

    if (this.integerOnly && this.roundUpto !== 0) {
      return [
        {
          isValid: false,
          message: "Cannot have both integer only and round upto",
        },
      ];
    }

    return [];
  }

  public validateInput(input: string): Array<ValidationErrors> {
    if (input === "" && this.isRequired) {
      return [{ isValid: false, message: "Input is required" }];
    }
    if (isNaN(Number(input))) {
      return [{ isValid: false, message: "Input is not a number" }];
    }

    if (this.integerOnly) {
      if (input.includes(".")) {
        return [{ isValid: false, message: "Only integers are allowed" }];
      }
    }

    const value = this.integerOnly ? parseInt(input) : parseFloat(input);
    if (value < this.minValue || value > this.maxValue) {
      return [
        {
          isValid: false,
          message: `Value must be between ${this.minValue} and ${this.maxValue}`,
        },
      ];
    }

    if (this.roundUpto > 0) {
      const parts = input.split(".");
      if (parts.length > 1 && parts[1].length > this.roundUpto) {
        return [
          {
            isValid: false,
            message: `Only ${this.roundUpto} decimal places are allowed`,
          },
        ];
      }
    }
    return [];
  }
}
