export interface TCustomHeader {
  Authorization: string;
  "Content-Type": string;
}

export class CustomHeaders {
  constructor(private idToken: string) {}

  getHeader = (): TCustomHeader => {
    return {
      Authorization: "Bearer " + this.idToken,
      "Content-Type": "application/json",
    };
  };
}
