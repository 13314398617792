import { Field } from "./redux/fieldSlice";

// FIX_ME: This is a temporary hardcoded value
const BASE_URL = "http://localhost:8081";

export class FieldService {
  private userUuid: string;
  constructor() {
    // FIX_ME: Fetch actual userUuid from auth service
    this.userUuid = "123";
  }

  handleAddFieldEvent = async (field: Field): Promise<void> => {
    fetch(`${BASE_URL}/fields`, {
      method: "POST",
      body: JSON.stringify({
        ...field,
        userUuid: this.userUuid,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  handleFetchAllFields = async (datasetUuid: string): Promise<Field[]> => {
    const response = await fetch(`${BASE_URL}/fields/dataset/${datasetUuid}`);
    return response.json();
  };

  updateField = async (field: Field): Promise<void> => {
    fetch(`${BASE_URL}/fields/${field.uuid}`, {
      method: "PATCH",
      body: JSON.stringify({
        ...field,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}
