import { BaseService } from "../../../services/baseService";
import { Business } from "../business.model";

export class BusinessService extends BaseService {
  static ROOT_URL = "/businesses";
  public genGetBusinessesForUserX = async (): Promise<Array<Business>> => {
    return await this.genAuthenticatedGetX(`${BusinessService.ROOT_URL}/user`);
  };
  public genCreateBusinessX = async (business: Business): Promise<void> => {
    await this.genAuthenticatedPostX(`${BusinessService.ROOT_URL}`, business);
  };

  public genDeleteBusinessX = async (uuid: string): Promise<void> => {
    await this.genAuthenticatedDeleteX(`${BusinessService.ROOT_URL}/${uuid}`);
  };

  public genUpdateBusinessX = async (business: Business): Promise<void> => {
    await this.genAuthenticatedPatchX(
      `${BusinessService.ROOT_URL}/${business.uuid}`,
      business
    );
  };

  public genFetchAllDatasetsByBusinessUuidX = async (
    businessUuid: string
  ): Promise<any> => {
    return await this.genAuthenticatedGetX(
      `${BusinessService.ROOT_URL}/${businessUuid}/datasets`
    );
  };
}
