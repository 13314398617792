import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { businessSlice } from "../pages/business/redux/businessSlice";
import { datasetSlice } from "../pages/dataset/redux/datasetSlice";
import { fieldSlice } from "../pages/fieldMetadata/redux/fieldSlice";
import { businessUserSlice } from "../pages/business/redux/businessUserSlice";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import { activeCellSlice } from "../pages/dataset/redux/activeCellSlice";
import { dataSlice } from "../pages/dataset/redux/dataSlice";
import { datasetSettingsSlice } from "../pages/dataset/redux/detasetSettingsSlice";
import { authSlice } from "../pages/auth/redux/authSlice";
import { actionStatusSlice } from "../pages/actions/redux/actionSlice";
import { inputModalSlice } from "../pages/dataset/redux/inputModalSlice";
import { routeSlice } from "../pages/dashboard/redux/routeSlice";
import { userRoleSlice } from "../pages/user/redux/userRoleSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [authSlice.name],
};

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  business: businessSlice.reducer,
  dataset: datasetSlice.reducer,
  field: fieldSlice.reducer,
  activeCell: activeCellSlice.reducer,
  data: dataSlice.reducer,
  datasetSettings: datasetSettingsSlice.reducer,
  actionStatus: actionStatusSlice.reducer,
  inputModal: inputModalSlice.reducer,
  businessUser: businessUserSlice.reducer,
  route: routeSlice.reducer,
  userRole: userRoleSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
