export type TokenType =
  | "letter"
  | "digit"
  | "whitespace"
  | "character"
  | "boolean"
  | "date"
  | "json"
  | "time"
  | "email"
  | "url"
  | "rich_text"
  | "list";

export const TokenTypes: Record<TokenType, string> = {
  letter: "letter",
  digit: "digit",
  whitespace: "whitespace",
  character: "character",
  boolean: "boolean",
  date: "date",
  time: "time",
  json: "json",
  email: "email",
  url: "url",
  rich_text: "rich_text",
  list: "list",
};
