import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  ActionStatusType,
  addPendingActionStatus,
  clearActionStatusByKey,
  selectStatusByEventKey,
} from "../actions/redux/actionSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { upsertRtfDataThunkFunction } from "./redux/dataSlice";
import { selectValueForCell } from "./redux/selectors/cellValue.selector";
import {
  hideModal as hideEditorModal,
  selectActiveInputModalCell,
  selectIsInputModalVisible,
} from "./redux/inputModalSlice";
import { ruleConfigFactory } from "../../services/rules/RuleConfigFactory";
import { selectField } from "../fieldMetadata/redux/fieldSlice";
import { ValidationErrors } from "../../services/rules/ErrorMessage";
import { RtfActions } from "./redux/actions/rtfActions";

export const RtfInputModal = (props: {}) => {
  const activeCell = useAppSelector((state) =>
    selectActiveInputModalCell(state)
  );
  const existingValue = useAppSelector((state) =>
    selectValueForCell(state, activeCell?.fieldUuid, activeCell?.row)
  );
  const appDispatch = useAppDispatch();

  const valueLoadStatus = useAppSelector((state) =>
    selectStatusByEventKey(state, RtfActions.LOAD_RTF_VALUE)
  );

  const valueUpdateStatus = useAppSelector((state) =>
    selectStatusByEventKey(state, RtfActions.UPDATE_RTF_VALUE)
  );

  const handleClose = () => {
    appDispatch(clearActionStatusByKey({ key: RtfActions.LOAD_RTF_VALUE }));
    appDispatch(clearActionStatusByKey({ key: RtfActions.UPDATE_RTF_VALUE }));
    appDispatch(hideEditorModal());
  };

  const handleChange = (__value: string) => {
    appDispatch(clearActionStatusByKey({ key: RtfActions.UPDATE_RTF_VALUE }));
    setValue(__value);
  };

  const [errors, setErrors] = useState<string>("");
  const [value, setValue] = useState<string>(existingValue ?? "");
  useEffect(() => {
    setValue(existingValue ?? "");
  }, [existingValue]);

  const field = useAppSelector((state) =>
    selectField(state, activeCell?.fieldUuid)
  );

  useEffect(() => {
    const rule = field?.configStr;
    if (field == null || rule == null) {
      return;
    }
    var parsedRule: string | null = null;
    try {
      if (rule !== "") {
        parsedRule = JSON.parse(rule);
      }
    } catch (e: any) {
      // FIX_ME: Add logging
      alert(`Failed to parse rule: ${e}`);
      return;
    }

    if (parsedRule == null) {
      return;
    }
    const _config = ruleConfigFactory.createConfig(field.type, parsedRule);
    const validationErrors: Array<ValidationErrors> | undefined =
      _config?.validateInput(stripHtmlTags(value));
    if (validationErrors?.length === 0) {
      setErrors("");
    } else {
      setErrors(validationErrors.map((error) => error.message).join(", "));
    }
  }, [field, value]);

  const show = useAppSelector((state) => selectIsInputModalVisible(state));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* FIX_ME: Replace with Quill V2 */}
        <ReactQuill theme="snow" value={value} onChange={handleChange} />
        {valueUpdateStatus?.type === ActionStatusType.FAILURE ? (
          <div style={{ color: "red" }}>{valueUpdateStatus.message}</div>
        ) : null}
        {valueUpdateStatus?.type === ActionStatusType.SUCCESS ? (
          <div style={{ color: "green" }}>Data saved</div>
        ) : null}
        {errors ? <div style={{ color: "red" }}>{errors}</div> : null}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={valueUpdateStatus?.type === ActionStatusType.PENDING}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant="primary"
          disabled={valueUpdateStatus?.type != null}
          onClick={() => {
            appDispatch(
              upsertRtfDataThunkFunction({
                fieldUuid: activeCell.fieldUuid!,
                row: activeCell.row,
                value: value,
                datasetUuid: activeCell.datasetUuid!,
              })
            );
          }}
        >
          {valueLoadStatus?.type === ActionStatusType.PENDING ? (
            <Spinner size="sm" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : null}
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const stripHtmlTags = (htmlString: string) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  return tempDiv.textContent || ""; // Fallback if empty
};
