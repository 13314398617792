import { Socket, io } from "socket.io-client";

import { BaseService } from "../services/baseService";

export class SocketService extends BaseService {
  public socket: Socket | null = null;
  static serviceInstance: SocketService;

  constructor() {
    super();
    return SocketService.serviceInstance || this;
  }

  static maybeGetSocket = (): Socket | null => {
    if (this.serviceInstance?.socket) {
      return this.serviceInstance.socket;
    }
    return null;
  };

  static genInit = async () => {
    this.serviceInstance = new SocketService();
    this.serviceInstance.socket =
      await this.serviceInstance.initAuthorizedSocket();
  };

  static genGetSocketService = async () => {
    if (this.serviceInstance?.socket) {
      return this.serviceInstance;
    }
    this.serviceInstance = new SocketService();
    this.serviceInstance.socket =
      await this.serviceInstance.initAuthorizedSocket();
    return this.serviceInstance;
  };

  private initAuthorizedSocket = async (): Promise<Socket | null> => {
    const baseService = new BaseService();
    const idtoken = await baseService.genGetIdToken();
    if (!idtoken) {
      return null;
    }
    const socket = io(BaseService.API_HOST_URL, {
      extraHeaders: { Authorization: `Bearer ${idtoken}` },
    });
    socket.connect();
    this.addListeners();
    return socket;
  };

  public destroySocketConn = async () => {
    this.socket?.disconnect();
  };

  private addListeners = () => {
    if (this.socket == null) {
      return;
    }
    console.log("Adding listeners to socket - ", this.socket);
    this.socket.on("connect", () => {
      console.log("Socket connected!");
    });

    this.socket.on("connect_error", (err: any) => {
      console.error("Socket connection error:", err);
    });

    this.socket.on("disconnect", (reason: any) => {
      console.log("Socket disconnected:", reason);
    });

    this.socket.onAny((event: any, ...args: any) => {
      console.log(`Received event '${event}':`, args);
    });
  };
}
