import React, { useState } from "react";
import {
  BusinessRoleInvitationStatus,
  BusinessRoleToString,
} from "../business/business.model";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import styles from "./UserRoles.module.css";
import { useAppSelector } from "../../redux/hooks";
import {
  BusinessUserWithBusinessName,
  leaveBusinessThunk,
  selectRoles,
  updateUsersBusinessRoleThunk,
} from "./redux/userRoleSlice";
import { useDispatch } from "react-redux";
import {
  ActionStatusType,
  selectStatusByEventKey,
} from "../actions/redux/actionSlice";
import { userRoleActionKeys } from "./redux/actionKeys";
import { NavLink } from "react-router-dom";
import { DeletionModal } from "../components/DeletionModal";

export const UserRoles = () => {
  const roles = useAppSelector((state) => selectRoles(state));
  const fetchStatus = useAppSelector((state) =>
    selectStatusByEventKey(state, userRoleActionKeys.FETCH_USER_ROLES)
  );

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedBusinessUuid, setSelectedBusinessUuid] = useState("");

  const dispatch = useDispatch();

  const handleLeaveBusinessClick = (
    businessUuid: React.SetStateAction<string>
  ) => {
    setSelectedBusinessUuid(businessUuid);
    setShowConfirmationModal(true);
  };

  const handleConfirmLeave = () => {
    if (selectedBusinessUuid) {
      dispatch(leaveBusinessThunk(selectedBusinessUuid));
    }
    setShowConfirmationModal(false);
  };

  const handleCancelLeave = () => {
    setShowConfirmationModal(false);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: "120px",
        marginRight: "120px",
      }}
    >
      <Container style={{ marginTop: "3rem" }}>
        <Row>
          <Col md={12}>
            <div
              style={{
                border: "1px solid #E2E8F0",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.5rem",
                padding: "2rem",
              }}
            >
              <h3
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#1A202C",
                  marginBottom: "1rem",
                }}
              >
                Business Roles
              </h3>
              {fetchStatus?.type === ActionStatusType.PENDING && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Spinner animation="border" />
                </div>
              )}
              <div>
                {roles.map((businessUserRole) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                      }}
                    >
                      <NavLink
                        to={`/business/${businessUserRole.businessUuid}`}
                        style={{
                          fontSize: "1rem",
                          color: "#1A202C",
                          marginBottom: "1rem",
                          width: "220px",
                        }}
                      >
                        {businessUserRole.businessName}
                      </NavLink>

                      <p
                        style={{
                          fontSize: "1rem",
                          color: "#1A202C",
                          marginBottom: "1rem",
                          width: "220px",
                          marginLeft: "6rem",
                        }}
                      >
                        {BusinessRoleToString(businessUserRole.role)}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          marginBottom: "1rem",
                          width: "220px",
                        }}
                      >
                        <AcceptRejecButton
                          businessUser={businessUserRole}
                          invitationStatus={businessUserRole.invitationStatus}
                          handleLeaveBusinessClick={handleLeaveBusinessClick}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
        <DeletionModal
          show={showConfirmationModal}
          onHide={handleCancelLeave}
          deletionMessage="Are you sure you want to leave this business?"
          onDelete={handleConfirmLeave}
        />
      </Container>
    </div>
  );
};

const AcceptRejecButton = (props: {
  businessUser: BusinessUserWithBusinessName;
  invitationStatus: BusinessRoleInvitationStatus;
  handleLeaveBusinessClick: (businessUuid: string) => void;
}) => {
  const updateStatus = useAppSelector((state) =>
    selectStatusByEventKey(state, userRoleActionKeys.UPDATE_USER_ROLE)
  );
  const isUpdating = updateStatus?.type === ActionStatusType.PENDING;

  const dispatch = useDispatch();
  if (props.invitationStatus === BusinessRoleInvitationStatus.PENDING) {
    return (
      <>
        <Button
          className={styles.UserRoleActionButton}
          disabled={isUpdating}
          variant="outline-success"
          onClick={() => {
            dispatch(
              updateUsersBusinessRoleThunk({
                ...props.businessUser,
                invitationStatus: BusinessRoleInvitationStatus.ACCEPTED,
              })
            );
          }}
        >
          {isUpdating && <Spinner animation="border" />} &nbsp; Accept
        </Button>
        <Button
          className={styles.UserRoleActionButton}
          disabled={isUpdating}
          style={{ marginLeft: "12px" }}
          variant="outline-warning"
          onClick={() => {
            dispatch(
              updateUsersBusinessRoleThunk({
                ...props.businessUser,
                invitationStatus: BusinessRoleInvitationStatus.DECLINED,
              })
            );
          }}
        >
          {isUpdating && <Spinner animation="border" size="sm" />} &nbsp;
          Decline
        </Button>
      </>
    );
  }
  return (
    <Button
      disabled={isUpdating}
      className={styles.UserRoleActionButton}
      variant="outline-danger"
      onClick={() =>
        props.handleLeaveBusinessClick(props.businessUser.businessUuid)
      }
    >
      {isUpdating && <Spinner animation="border" size="sm" />} &nbsp; Leave
    </Button>
  );
};
