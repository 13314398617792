import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useAppDispatch } from "../../redux/hooks";
import { createDatasetThunk } from "../dataset/redux/datasetSlice";

interface CreateDatasetModalProps {
  show: boolean;
  onHide: () => void;
  businessUuid: string;
}

export const CreateDatasetModal: React.FC<CreateDatasetModalProps> = ({
  show,
  onHide,
  businessUuid,
}) => {
  const [datasetName, setDatasetName] = useState("");
  const dispatch = useAppDispatch();

  const handleCreate = () => {
    if (datasetName.trim() !== "") {
      dispatch(createDatasetThunk(datasetName.trim(), businessUuid));
      setDatasetName("");
      onHide();
    }
  };

  const handleCancel = () => {
    setDatasetName("");
    onHide();
  };

  return (
    <Modal show={show} onHide={handleCancel} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Create a Dataset</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "120px" }}>
        <p style={{ fontSize: "16px" }}>Enter a name for the new dataset:</p>
        <Form.Control
          type="text"
          value={datasetName}
          onChange={(e) => setDatasetName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleCreate}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
