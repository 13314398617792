import { ValidationErrors } from "./ErrorMessage";
import { TokenType } from "./TokenType";

export interface TokenConfigData {
  type: TokenType;
  isRequired: boolean;
}

export class AbstractTokenConfig {
  public constructor(
    public type: TokenType,
    public isRequired: boolean = true
  ) {}

  public validateRules(): Array<ValidationErrors> {
    throw new Error("Child class should implement this method");
  }

  public validateInput(input: string): Array<ValidationErrors> {
    throw new Error("Child class should implement this method");
  }

  public sanitize = (value: string) => {
    return "Unimplemented for this datatype \n " + (value ?? "");
  };
}
