import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../redux/store";
import { DatasetService } from "../services/dataset.service";
import { v4 as uuidV4 } from "uuid";

export interface Dataset {
  uuid: string;
  name: string;
  businessUuid: string;
}

interface DatasetState {
  datasets: Array<Dataset>;
}

const initialState: DatasetState = {
  datasets: [],
};

export const datasetSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    _addDataset: (state, action: PayloadAction<Dataset>) => {
      return { datasets: [...state.datasets, action.payload] };
    },
    _deleteDataset: (state, action: PayloadAction<string>) => {
      return {
        datasets: state.datasets.filter(
          (dataset) => dataset.uuid !== action.payload
        ),
      };
    },
    _updateDataset: (
      state,
      action: PayloadAction<{
        uuid: string;
        name: string;
      }>
    ) => {
      return {
        datasets: state.datasets.map((dataset) =>
          dataset.uuid === action.payload.uuid
            ? {
                ...dataset,
                name: action.payload.name,
              }
            : dataset
        ),
      };
    },
    _initForBusiness: (state, action: PayloadAction<Dataset[]>) => {
      return { datasets: action.payload };
    },
  },
});

export const { _addDataset, _deleteDataset, _updateDataset, _initForBusiness } =
  datasetSlice.actions;

export const selectDatasetsByBusinessUuid = (
  state: RootState,
  businessUuid: string
) =>
  state.dataset.datasets.filter(
    (dataset: Dataset) => dataset.businessUuid === businessUuid
  );

export const selectDatasetById = (state: RootState, uuid: string) =>
  state.dataset.datasets.find((dataset: Dataset) => dataset.uuid === uuid);

export const createDatasetThunk =
  (name: string, businessUuid: string): any =>
  async (dispatch: any) => {
    const uuid = uuidV4();
    await new DatasetService().createDatasetX(uuid, businessUuid, name);
    dispatch(_addDataset({ uuid, name, businessUuid } as Dataset));
  };

export const deleteDatasetThunk =
  (uuid: string): any =>
  async (dispatch: any) => {
    await new DatasetService().deleteDatasetX(uuid);
    dispatch(_deleteDataset(uuid));
  };

export const updateDatasetThunk =
  (uuid: string, name: string): any =>
  async (dispatch: any) => {
    await new DatasetService().updateDatasetX(uuid, name);
    dispatch(_updateDataset({ uuid, name } as Dataset));
  };
