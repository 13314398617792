export interface Business {
  uuid: string;
  name: string;
}
// Always Keep in sync with be/src/businesses/businesses.model.ts
export enum BusinessRole {
  OWNER,
  ADMIN,
  VIEWER,
  EDITOR,
}

export enum BusinessRoleInvitationStatus {
  PENDING,
  ACCEPTED,
  DECLINED,
}

export const BusinessRoleInvitationStatusToString = (
  status: BusinessRoleInvitationStatus
): string => {
  switch (status) {
    case BusinessRoleInvitationStatus.PENDING:
      return "Pending";
    case BusinessRoleInvitationStatus.ACCEPTED:
      return "Active";
    case BusinessRoleInvitationStatus.DECLINED:
      return "Declined";
  }
};

export const BusinessRoles = [
  BusinessRole.OWNER,
  BusinessRole.ADMIN,
  BusinessRole.VIEWER,
  BusinessRole.EDITOR,
];

export const BusinessRoleToString = (role: BusinessRole): string => {
  switch (role) {
    case BusinessRole.OWNER:
      return "Owner";
    case BusinessRole.ADMIN:
      return "Admin";
    case BusinessRole.VIEWER:
      return "Viewer";
    case BusinessRole.EDITOR:
      return "Editor";
  }
};
