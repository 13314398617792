import {
  createSelector,
  createSlice,
  Dispatch,
  PayloadAction,
} from "@reduxjs/toolkit";
import type { RootState } from "../../../redux/store";
import { setActiveCell } from "../../../pages/dataset/redux/activeCellSlice";
import { FieldService } from "../field.service";
import {
  addFailedActionStatus,
  addPendingActionStatus,
  clearActionStatusByKey,
} from "../../actions/redux/actionSlice";

export const FieldTypes = {
  text: "text",
  number: "number",
  date: "date",
  boolean: "boolean",
  advanced: "advanced",
  json: "json",
  email: "email",
  url: "url",
  rich_text: "rich_text",
  list: "list",
};
export type FieldType = (typeof FieldTypes)[keyof typeof FieldTypes];

export interface Field {
  uuid: string;
  datasetUuid: string;
  name: string;
  type: FieldType;
  index: number;
  configStr: string;
}

interface FieldState {
  fields: Array<Field>;
}

const initialState: FieldState = {
  fields: [],
};

export const fieldSlice = createSlice({
  name: "field",
  initialState,
  reducers: {
    _addField: (state, action: PayloadAction<Field>) => {
      const maxIndex = state.fields.reduce(
        (max, field) => Math.max(max, field.index),
        0
      );
      state.fields.push({
        ...action.payload,
        index: maxIndex + 1,
      });
    },
    deleteField: (state, action: PayloadAction<string>) => {
      state.fields = state.fields.filter(
        (field) => field.uuid !== action.payload
      );
    },
    _updateField: (state, action: PayloadAction<Field>) => {
      state.fields = state.fields.map((field) =>
        field.uuid === action.payload.uuid ? action.payload : field
      );
    },
    deleteFieldsByDatasetId: (state, action: PayloadAction<string>) => {
      state.fields = state.fields.filter(
        (field) => field.datasetUuid !== action.payload
      );
    },
    _setFields: (state, action: PayloadAction<Array<Field>>) => {
      return {
        ...state,
        fields: action.payload,
      };
    },
  },
});

export const {
  _addField,
  deleteField,
  _updateField,
  deleteFieldsByDatasetId,
  _setFields,
} = fieldSlice.actions;

const selectUuid = (state: RootState, datasetUuid: string) => datasetUuid;
const selectFields = (state: RootState) => state.field.fields;

export const selectFieldsByDatasetUuid = createSelector(
  [selectFields, selectUuid],
  (fields, datasetUuid) =>
    fields
      .filter((field) => field.datasetUuid === datasetUuid)
      .sort((a, b) => a.index - b.index)
);

export const selectFieldsUuidsByDatasetUuid = createSelector(
  [selectFields, selectUuid],
  (fields, datasetUuid) =>
    fields
      .filter((field) => field.datasetUuid === datasetUuid)
      .sort((a, b) => a.index - b.index)
      .map((field) => field.uuid)
);

export const selectFirstFieldByDatasetUuid = (
  state: RootState,
  datasetUuid: string
) => state.field.fields.find((field) => field.datasetUuid === datasetUuid);

export const selectField = (
  state: RootState,
  uuid: string | null
): Field | null => {
  if (uuid == null) {
    return null;
  }
  return state.field.fields.find((field) => field.uuid === uuid) || null;
};

export const selectRuleByFieldUuid = (
  state: RootState,
  fieldUuid: string
): string => {
  const field = state.field.fields.find((field) => field.uuid === fieldUuid);
  return field?.configStr || "";
};

export const deleteFieldThunkFunction = (fieldId: string): any => {
  return (dispatch: Dispatch, getState: any) => {
    const activeCell = getState().activeCell.cell;
    var indexUpdated = false;
    if (activeCell?.datasetUuid != null) {
      const fields = selectFieldsByDatasetUuid(
        getState(),
        activeCell.datasetUuid
      );
      var lastField: Field | null = null;
      if (fields.length > 1 && fields[0].uuid === fieldId) {
        lastField = fields[1];
      } else {
        for (var field of fields) {
          if (field.uuid === fieldId) {
            break;
          }
          lastField = field;
        }
      }

      if (lastField != null) {
        dispatch(
          setActiveCell({
            datasetUuid: activeCell.datasetUuid,
            fieldUuid: lastField.uuid,
            row: activeCell.row,
          })
        );
        indexUpdated = true;
      }
    }
    dispatch(deleteField(fieldId));
    // If no active dataset, clear active cell
    if (!indexUpdated) {
      dispatch(setActiveCell({ datasetUuid: null, fieldUuid: null, row: 0 }));
    }
  };
};

export const addFieldThunkFunction =
  (
    fieldUuid: string,
    datasetUuid: string,
    type: FieldType,
    name: string
  ): any =>
  async (dispatch: Dispatch, getState: any) => {
    const numFields = selectFieldsByDatasetUuid(getState(), datasetUuid).length;
    const configStr = "";
    await new FieldService().handleAddFieldEvent({
      uuid: fieldUuid,
      datasetUuid,
      name,
      type,
      index: numFields,
      configStr,
    });
    dispatch(
      _addField({
        uuid: fieldUuid,
        datasetUuid,
        type,
        name,
        configStr,
      } as Field)
    );
  };

export const FETCHING_FIELDS_EVENT_KEY = "FETCHING_FIELDS";
export const fetchAllFieldsThunkFunction = (datasetUuid: string): any => {
  return async (dispatch: Dispatch) => {
    dispatch(addPendingActionStatus({ key: FETCHING_FIELDS_EVENT_KEY }));
    try {
      const fields = (
        await new FieldService().handleFetchAllFields(datasetUuid)
      ).sort((a, b) => a.index - b.index);
      dispatch(_setFields(fields));
      if (fields.length > 0) {
        dispatch(
          setActiveCell({ datasetUuid, fieldUuid: fields[0].uuid, row: 0 })
        );
      }
      dispatch(clearActionStatusByKey({ key: FETCHING_FIELDS_EVENT_KEY }));
    } catch (e) {
      dispatch(addFailedActionStatus({ key: FETCHING_FIELDS_EVENT_KEY }));
    }
  };
};

export const updateFieldThunkFunction = (field: Field): any => {
  return async (dispatch: Dispatch) => {
    await new FieldService().updateField(field);
    dispatch(_updateField(field));
  };
};
