import { Button, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectActiveCell } from "../redux/activeCellSlice";
import { selectValueForCell } from "../redux/selectors/cellValue.selector";
import { selectField } from "../../fieldMetadata/redux/fieldSlice";
import { ruleConfigFactory } from "../../../services/rules/RuleConfigFactory";
import { upsertDataThunkFunction } from "../redux/dataSlice";

export const SanitizerEditor = () => {
  const appDispatch = useAppDispatch();

  const activeCell = useAppSelector((state) => selectActiveCell(state).cell);
  const activeCellValue = useAppSelector((state) =>
    selectValueForCell(state, activeCell.fieldUuid, activeCell.row)
  );
  const field = useAppSelector((state) =>
    selectField(state, activeCell.fieldUuid)
  );
  const config = ruleConfigFactory.createConfig(
    field?.type || "string",
    field?.configStr || ""
  );

  const sanitizedValue = config.sanitize(activeCellValue);

  return (
    <div style={{ marginTop: 10 }}>
      <Form.Control
        as="textarea"
        disabled={true}
        rows={4}
        value={activeCellValue}
      />
      <p style={{ marginTop: 20 }}>Sanitized Data</p>
      {/* TODO(Taman/critical): Implement sanitizer plugins */}
      <Form.Control
        as="textarea"
        disabled={true}
        rows={4}
        value={sanitizedValue}
      />
      <Button
        style={{
          marginTop: 10,
          backgroundColor: "#7749F8",
        }}
        onClick={() => {
          if (activeCell.fieldUuid == null || activeCell.datasetUuid == null) {
            return;
          }
          appDispatch(
            upsertDataThunkFunction({
              row: activeCell.row,
              fieldUuid: activeCell.fieldUuid,
              value: sanitizedValue,
              datasetUuid: activeCell.datasetUuid,
            })
          );
        }}
      >
        Update Data
      </Button>
    </div>
  );
};
