import { BaseService } from "../../../services/baseService";
import { SocketService } from "../../../events/socketService";
import type { TCellData } from "../redux/dataSlice";

export class DatasetService extends BaseService {
  static ROOT_URL = "/datasets";

  private socketService: SocketService;

  constructor() {
    super();
    this.socketService = new SocketService();
  }

  static DATA_CHANGE_REQUEST_FROM_CLIENT_EVENT =
    "data_change_request_from_client";
  static JOIN_ROOM_EVENT = "join_room_event";
  static REMOTE_UPDATE_INTERVAL =
    process.env.NODE_ENV === "production" ? 15000 : 3000;

  handleFieldUpdateEvents = async (
    data: Array<TCellData>,
    userUuid: string
  ): Promise<void> => {
    if (!this.socketService.socket) {
      this.socketService = await SocketService.genGetSocketService();
      // if socket is still not initialized, throw error
      if (!this.socketService.socket) {
        // TODO(Taman / critical): add logging
        throw new Error("Socket not initialized");
      }
    }
    this.socketService!.socket!.emit(
      DatasetService.DATA_CHANGE_REQUEST_FROM_CLIENT_EVENT,
      {
        data: data.map((d: TCellData) => {
          return {
            fieldUuid: d.fieldUuid,
            row: d.row,
            value: d.value,
          };
        }),
        userUuid,
      }
    );
  };

  createDatasetX = async (
    uuid: string,
    businessUuid: string,
    name: string
  ): Promise<void> => {
    await this.genAuthenticatedPostX(DatasetService.ROOT_URL, {
      uuid,
      businessUuid,
      name,
    });
  };

  deleteDatasetX = async (uuid: string): Promise<void> => {
    await this.genAuthenticatedDeleteX(`${DatasetService.ROOT_URL}/${uuid}`);
  };

  updateDatasetX = async (uuid: string, name: string): Promise<void> => {
    await this.genAuthenticatedPatchX(`${DatasetService.ROOT_URL}/${uuid}`, {
      name,
    });
  };

  getDatasetX = async (uuid: string): Promise<any> => {
    await this.genAuthenticatedGetX(`${DatasetService.ROOT_URL}/${uuid}`);
  };
}
