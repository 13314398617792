import { Dispatch, createSlice } from "@reduxjs/toolkit";
import {
  signInWithEmailService,
  signOutService,
  updateUserFullname,
  getCurUser,
  updateUserPassword,
  sendPasswordResetEmailService,
} from "../services/authServices";
import { SocketService } from "../../../events/socketService";

const initialState = {
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.user = action.payload;
    },
    signOut: (state) => {
      state.user = null;
    },
    updateUser: (state, action) => {
      if (state.user) {
        state.user = action.payload.user;
      }
    },
  },
});

export const { signIn, signOut, updateUser } = authSlice.actions;

export const selectUser = (state: any) => state.auth.user;
export const selectUserUid = (state: any) => state.auth?.user?.uid;

export const handlSignInThunk =
  (
    email: string,
    password: string,
    setErrorMessage: (error: string) => void
  ): any =>
  async (dispatch: Dispatch) => {
    // intentinally left out error handling for view
    try {
      const user = await signInWithEmailService(email, password);
      if (user) {
        dispatch(signIn(user));
        await SocketService.genInit();
      }
    } catch (error: any) {
      setErrorMessage(error.message ?? error);
    }
  };

export const handleForgotPasswordThunk =
  (
    email: string,
    setErrorMessage: (error: string) => void,
    setEmail: (isEmailSent: boolean) => void
  ): any =>
  async (dispatch: Dispatch) => {
    try {
      await sendPasswordResetEmailService({ email });
      setEmail(true);
    } catch (error: any) {
      setErrorMessage(error.message ?? error);
    }
  };

export const handleSignOutThunk = (): any => async (dispatch: Dispatch) => {
  await signOutService();
  dispatch(signOut());
  const socketService = await SocketService.genGetSocketService();
  if (socketService?.socket) {
    socketService.destroySocketConn();
  }
};

export const handleUpdateNameThunk =
  (name: string, setErrorMessage: (error: string) => void): any =>
  async (dispatch: Dispatch) => {
    try {
      await updateUserFullname({ name });
      const updatedUser = await getCurUser();
      dispatch(updateUser({ user: updatedUser }));
    } catch (error: any) {
      setErrorMessage(error.message ?? error);
    }
  };

export const handleUpdatePasswordThunk =
  (
    currentPassword: string,
    newPassword: string,
    setpasswordErrorMessage: (error: string) => void
  ): any =>
  async (dispatch: Dispatch) => {
    let updateSuccess = false; // Track success state

    const handleLoading = () => {
      // Handle loading state
      return {};
    };

    const handleSuccess = () => {
      // Handle success state
      updateSuccess = true;
      return {};
    };

    const handleError = (error: string) => {
      // Handle error state
      setpasswordErrorMessage(error);
      updateSuccess = false;
      return {};
    };

    try {
      await updateUserPassword(
        currentPassword,
        newPassword,
        handleLoading,
        handleSuccess,
        handleError
      );
      return updateSuccess;
    } catch (error: any) {
      setpasswordErrorMessage(error.message ?? error);
      return false;
    }
  };
