import { createSelector } from "@reduxjs/toolkit";
export const selectValueForCell = createSelector(
  [
    (state) => state.data.data,
    (state, fieldUuid) => fieldUuid,
    (state, fieldUuid, row) => row,
  ],
  (allData, fieldUuid, row) => {
    if (!allData || !fieldUuid || !row) {
      return null;
    }
    return allData[fieldUuid]?.[row]?.value;
  }
);
