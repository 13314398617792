import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

import { BusinessUser } from "../../business/redux/businessUserSlice";
import { RootState } from "../../../redux/store";
import { UserRoleService } from "../services/userRoleService";
import {
  addFailedActionStatus,
  addPendingActionStatus,
  clearActionStatusByKey,
} from "../../actions/redux/actionSlice";
import { userRoleActionKeys } from "./actionKeys";
import { BusinessRoleInvitationStatus } from "../../business/business.model";

export interface BusinessUserWithBusinessName extends BusinessUser {
  businessName: string;
}

const initialState = {
  roles: [] as BusinessUserWithBusinessName[],
};

export const userRoleSlice = createSlice({
  name: "userRole",
  initialState,
  reducers: {
    setRoles: (
      state,
      action: PayloadAction<BusinessUserWithBusinessName[]>
    ) => {
      state.roles = action.payload;
    },

    updateRole(state, action: PayloadAction<BusinessUserWithBusinessName>) {
      state.roles = state.roles.map((role) =>
        role.businessUuid === action.payload.businessUuid
          ? action.payload
          : role
      );
    },

    deleteRole(
      state,
      action: PayloadAction<{
        businessUuid: string;
      }>
    ) {
      state.roles = state.roles.filter(
        (role) => role.businessUuid !== action.payload.businessUuid
      );
    },
  },
});

export const { setRoles, updateRole, deleteRole } = userRoleSlice.actions;

export const selectRoles = (state: RootState) => state.userRole.roles;

export const fetchUserRolesThunk = (): any => {
  return async (dispatch: Dispatch) => {
    const eventKey = userRoleActionKeys.FETCH_USER_ROLES;
    try {
      dispatch(addPendingActionStatus({ key: eventKey }));
      const roles = await new UserRoleService().fetchUserRoles();
      dispatch(setRoles(roles));
      dispatch(
        clearActionStatusByKey({
          key: eventKey,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: eventKey,
          message: e.message ?? "Failed to fetch user roles",
        })
      );
    }
  };
};

export const updateUsersBusinessRoleThunk = (
  role: BusinessUserWithBusinessName
): any => {
  return async (dispatch: Dispatch) => {
    const eventKey = userRoleActionKeys.UPDATE_USER_ROLE;
    try {
      dispatch(addPendingActionStatus({ key: eventKey }));
      await new UserRoleService().updateUsersBusinessRole(role);
      dispatch(updateRole(role));
      dispatch(
        clearActionStatusByKey({
          key: eventKey,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: eventKey,
          message: e.message ?? "Failed to update user role",
        })
      );
    }
  };
};

export const leaveBusinessThunk = (businessUuid: string): any => {
  return async (dispatch: Dispatch) => {
    const eventKey = userRoleActionKeys.UPDATE_USER_ROLE;
    try {
      dispatch(addPendingActionStatus({ key: eventKey }));
      await new UserRoleService().leaveBusiness(businessUuid);
      dispatch(deleteRole({ businessUuid }));
      dispatch(
        clearActionStatusByKey({
          key: eventKey,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: eventKey,
          message: e.message ?? "Failed to leave business",
        })
      );
    }
  };
};
