import { useAppSelector } from "../../redux/hooks";
import styles from "./DatasetHome.module.css";
import { InputCellContainer } from "./InputCellContainer";
import { selectFieldsUuidsByDatasetUuid } from "../fieldMetadata/redux/fieldSlice";

export const DataRow = (props: {
  datasetUuid: string;
  index: number;
  style: any;
}): React.ReactElement => {
  const fieldUuids = useAppSelector((state) =>
    selectFieldsUuidsByDatasetUuid(state, props.datasetUuid)
  );

  return (
    <div
      style={{
        ...props.style, // DON'T REMOVE. this is critical for react-window to work
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        height: "40px",
      }}
      key={props.index}
    >
      <div
        className={styles.datasetIndexCell}
        style={{
          height: "40px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "0.5px solid black",
        }}
        key={`div-${props.index}`}
      >
        {props.index}
      </div>
      {fieldUuids.map((fieldUuid) => (
        <div className={`${styles.datasetCell}`} key={`div-${fieldUuid}`}>
          <InputCellContainer
            datasetUuid={props.datasetUuid}
            row={props.index}
            fieldUuid={fieldUuid}
          />
        </div>
      ))}
      <div
        className={`${styles.datasetCell}`}
        key={`div-empty-field-${props.index}`}
        style={{
          height: "40px",
          minWidth: "40px",
          maxWidth: "40px",
          border: 0,
        }}
      >
        {/* TODO(Taman): Add the delete icon here */}
        {/* placeholder only */}
      </div>
    </div>
  );
};
