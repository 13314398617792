import {
  createSelector,
  createSlice,
  Dispatch,
  PayloadAction,
} from "@reduxjs/toolkit";
import type { RootState } from "../../../redux/store";
import { BusinessRole, BusinessRoleInvitationStatus } from "../business.model";
import { BusinessUserService } from "../services/businessUser.service";
import {
  addFailedActionStatus,
  addPendingActionStatus,
  addSuccessfulActionStatus,
} from "../../actions/redux/actionSlice";

export interface BusinessUser {
  userUuid: string;
  businessUuid: string;
  role: BusinessRole;
  displayName: string;
  invitationStatus: BusinessRoleInvitationStatus;
}

interface BusinessUserState {
  roles: { [key: string]: Array<BusinessUser> };
}

const initialState: BusinessUserState = {
  roles: {},
};

export const businessUserSlice = createSlice({
  name: "businessUser",
  initialState,
  reducers: {
    addBusinessRole: (
      state,
      action: PayloadAction<{
        businessUser: BusinessUser;
      }>
    ) => {
      const businessUuid = action.payload.businessUser.businessUuid;
      const existingBusinessRoleList = state.roles[businessUuid];
      if (!existingBusinessRoleList) {
        state.roles[businessUuid] = [];
      }
      state.roles[businessUuid].push(action.payload.businessUser);
    },
    _deleteBusinessRole: (
      state,
      action: PayloadAction<{
        businessUuid: string;
        userUuid: string;
      }>
    ) => {
      state.roles[action.payload.businessUuid] = state.roles[
        action.payload.businessUuid
      ].filter(
        (businessUser) => businessUser.userUuid !== action.payload.userUuid
      );
    },
    _updateBusinessRole: (
      state,
      action: PayloadAction<{
        businessUser: {
          userUuid: string;
          businessUuid: string;
          role: BusinessRole;
        };
      }>
    ) => {
      const bizUuid = action.payload.businessUser.businessUuid;
      const existingList = state.roles[bizUuid];
      if (!existingList) {
        throw new Error("Business role not found");
      }

      const updatedList = existingList.map((businessUser) =>
        businessUser.userUuid === action.payload.businessUser.userUuid
          ? { ...businessUser, ...action.payload.businessUser }
          : businessUser
      );
      state.roles[bizUuid] = updatedList;
    },
    _setBusinessRoles: (
      state,
      action: PayloadAction<{
        businessUuid: string;
        businessUsers: Array<{
          userUuid: string;
          role: BusinessRole;
          displayName: string;
          invitationStatus: BusinessRoleInvitationStatus;
        }>;
      }>
    ) => {
      state.roles[action.payload.businessUuid] =
        action.payload.businessUsers.map((businessUser) => {
          return {
            userUuid: businessUser.userUuid,
            businessUuid: action.payload.businessUuid,
            role: businessUser.role,
            displayName: businessUser.displayName,
            invitationStatus: businessUser.invitationStatus,
          };
        });
    },
  },
});

export const {
  addBusinessRole,
  _deleteBusinessRole,
  _updateBusinessRole,
  _setBusinessRoles,
} = businessUserSlice.actions;

export const selectBusinessRoles = createSelector(
  (state: RootState) => state.businessUser.roles,
  (state: RootState, businessUuid: string) => businessUuid,
  (roles, businessUuid) => {
    return roles[businessUuid] ?? [];
  }
);

export const selectBusinessUser = createSelector(
  (state: RootState) => state.businessUser.roles,
  (state: RootState, businessUuid: string, userUuid: string) => ({
    businessUuid: businessUuid,
    userUuid: userUuid,
  }),
  (roles, { businessUuid, userUuid }) => {
    return roles[businessUuid]?.find(
      (businessUser) => businessUser.userUuid === userUuid
    );
  }
);

export enum BusinessActionStatusKey {
  ADD_USER_TO_BUSINESS = "ADD_USER_TO_BUSINESS",
  DELETE_USER_FROM_BUSINESS = "DELETE_USER_FROM_BUSINESS",
  FETCH_BUSINESS_USER_ROLE = "FETCH_BUSINESS_USER_ROLE",
  UPDATE_BUSINESS_USER_ROLE = "UPDATE_BUSINESS_USER_ROLE",
}

export const addUserToBusinessThunk =
  (email: string, businessUuid: string, role: BusinessRole): any =>
  async (dispatch: Dispatch) => {
    dispatch(
      addPendingActionStatus({
        key: BusinessActionStatusKey.ADD_USER_TO_BUSINESS,
      })
    );
    try {
      const gotRole = await new BusinessUserService().genAddUserToBusinessX(
        email,
        businessUuid,
        role
      );
      dispatch(
        addBusinessRole({
          businessUser: {
            // FIX_ME: Use the right userUuid from the response
            userUuid: gotRole.userUuid,
            businessUuid,
            role,
            displayName: gotRole.displayName,
            invitationStatus: BusinessRoleInvitationStatus.PENDING,
          },
        })
      );
      dispatch(
        addSuccessfulActionStatus({
          key: BusinessActionStatusKey.ADD_USER_TO_BUSINESS,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: BusinessActionStatusKey.ADD_USER_TO_BUSINESS,
          message: e.message,
        })
      );
    }
  };

export const removeUserFromBusinessThunk =
  (businessUuid: string, userUuid: string): any =>
  async (dispatch: Dispatch) => {
    const eventKey = BusinessActionStatusKey.DELETE_USER_FROM_BUSINESS;
    dispatch(
      addPendingActionStatus({
        key: eventKey,
      })
    );
    try {
      await new BusinessUserService().genRemoveUserFromBusinessX(
        businessUuid,
        userUuid
      );
      dispatch(
        _deleteBusinessRole({
          businessUuid,
          userUuid,
        })
      );
      dispatch(
        addSuccessfulActionStatus({
          key: eventKey,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: eventKey,
          message: e.message,
        })
      );
    }
  };

export const fetchUsersForBusinessThunk =
  (businessUuid: string): any =>
  async (dispatch: Dispatch) => {
    dispatch(
      addPendingActionStatus({
        key: BusinessActionStatusKey.FETCH_BUSINESS_USER_ROLE,
      })
    );
    try {
      const roles = await new BusinessUserService().genGetUsersForBusinessX(
        businessUuid
      );
      dispatch(_setBusinessRoles({ businessUsers: roles, businessUuid }));

      dispatch(
        addSuccessfulActionStatus({
          key: BusinessActionStatusKey.FETCH_BUSINESS_USER_ROLE,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: BusinessActionStatusKey.FETCH_BUSINESS_USER_ROLE,
          message: e.message,
        })
      );
    }
  };

export const updateBusinessUserRoleThunk = (
  businessUuid: string,
  userUuid: string,
  role: BusinessRole
): any => {
  return async (dispatch: Dispatch) => {
    const eventKey = BusinessActionStatusKey.UPDATE_BUSINESS_USER_ROLE;
    dispatch(addPendingActionStatus({ key: eventKey }));
    try {
      await new BusinessUserService().genUpdateBusinessUserRoleX(
        businessUuid,
        userUuid,
        role
      );
      dispatch(
        _updateBusinessRole({
          businessUser: {
            businessUuid,
            userUuid,
            role,
          },
        })
      );
      dispatch(addSuccessfulActionStatus({ key: eventKey }));
    } catch (e: any) {
      dispatch(addFailedActionStatus({ key: eventKey, message: e.message }));
    }
  };
};
