import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

interface EditModalProps {
  show: boolean;
  onHide: () => void;
  initialValue: string;
  onSave: (newValue: string) => void;
  editMessage: string;
}

export const EditModal: React.FC<EditModalProps> = ({
  show,
  onHide,
  initialValue,
  onSave,
  editMessage,
}) => {
  const [newValue, setNewValue] = useState(initialValue);

  const handleSave = () => {
    onSave(newValue);
    setNewValue("");
  };

  const handleCancel = () => {
    setNewValue("");
    onHide();
  };

  return (
    <Modal show={show} onHide={handleCancel} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Name</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "120px" }}>
        <p style={{ fontSize: "16px" }}>{editMessage}</p>
        <Form.Control
          type="text"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
