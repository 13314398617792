import { BaseService } from "../../../services/baseService";
import type { TCellData } from "../redux/dataSlice";

export class DataFieldService extends BaseService {
  static ROOT_URL = "/data-field";

  static DATA_CHANGE_REQUEST_FROM_CLIENT_EVENT =
    "data_change_request_from_client";
  static REMOTO_UPDATE_INTERVAL =
    process.env.NODE_ENV === "production" ? 15000 : 3000;

  getDataByDatasetX = async (uuid: string): Promise<Array<TCellData>> => {
    return await this.genAuthenticatedGetX(
      `${DataFieldService.ROOT_URL}/${uuid}/`
    );
  };
}
