import Nav from "react-bootstrap/Nav";
import { Navbar, NavDropdown, Container } from "react-bootstrap";
import {
  DatroxPage,
  datroxPages,
  selectActivePath,
  setActiveRoute,
} from "./redux/routeSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import styles from "./NavBar.module.css";
import { useEffect } from "react";
import { UserRoleNavItem } from "./UserRolesNavItem";
import type { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleSignOutThunk } from "../auth/redux/authSlice";

const NavBar = () => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const selectedTab = useAppSelector((state: RootState) =>
    selectActivePath(state)
  );
  useEffect(() => {
    const path = window.location.pathname.split("/").pop();
    appDispatch(setActiveRoute(path as DatroxPage));
  });
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ background: "rgba(119, 73, 248, 1)" }}
    >
      <Navbar.Brand href="#home" className={`${styles.NavbarBrand}`}>
        Datapler
      </Navbar.Brand>
      <Navbar.Toggle  aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className={`${styles.Nav}`}>
          <Nav.Link className={styles.CustomLink} href={datroxPages.home}>
            Businesses
          </Nav.Link>
          <NavDropdown
            title={<span style={{ color: "white" }}>My Account</span>}
            id="collapsible-nav-dropdown"
            className={styles.Dropdown}
          >
            <style>
            {`
                .nav-link.dropdown-toggle::after {
                  color: white;
                  transform: scale(1.5);
                }

              `}
            </style>
            <NavDropdown.Item
              className={styles.DropdownLink}
              href={datroxPages.userSettings}
            >
              User Settings
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className={styles.DropdownLink}
              onClick={() => {
                dispatch(handleSignOutThunk());
              }}
            >
              Sign out
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default NavBar;
