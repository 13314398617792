import { useAppSelector } from "../../../redux/hooks";
import { selectActiveCell } from "../redux/activeCellSlice";
import { selectValueForCell } from "../redux/selectors/cellValue.selector";
import { selectField } from "../../fieldMetadata/redux/fieldSlice";
import { ruleConfigFactory } from "../../../services/rules/RuleConfigFactory";

export const CellValidator = () => {
  const activeCell = useAppSelector((state) => selectActiveCell(state).cell);
  const activeCellValue = useAppSelector((state) =>
    selectValueForCell(state, activeCell.fieldUuid, activeCell.row)
  );
  const field = useAppSelector((state) =>
    selectField(state, activeCell.fieldUuid)
  );
  if (activeCell.row === 0 || field == null || !field.configStr) {
    return null;
  }
  const parsedRule = JSON.parse(field.configStr);
  const config = ruleConfigFactory.createConfig(field.type, parsedRule);

  const errors = config.validateInput(activeCellValue ?? "");

  if (errors.length > 0) {
    return (
      <div style={{ marginTop: 10 }}>
        <p>Validation Errors</p>
        <ul>
          {errors.map((error, idx) => (
            <li key={`error-${idx}`}>{error.message}</li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div>
      <p>No validation errors</p>
    </div>
  );
};
