import { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { selectUser } from "../auth/redux/authSlice";
import { useDispatch } from "react-redux";
import {
  handleUpdateNameThunk,
  handleUpdatePasswordThunk,
} from "../auth/redux/authSlice";
import { toast, Toaster } from "react-hot-toast";
const UserSettingsPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [name, setName] = useState(user.displayName);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setpasswordErrorMessage] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };
  const handleSubmitName = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await dispatch(handleUpdateNameThunk(name, setErrorMessage));
      setIsLoading(false);
      toast.success("Name updated successfully!");
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCurrentPasswordChange = (e: any) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e: any) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmitPassword = async (e: any) => {
    e.preventDefault();
    setIsLoadingPassword(true);
    setpasswordErrorMessage("");
    if (newPassword !== confirmPassword) {
      setpasswordErrorMessage("New password and confirm password must match");
      setIsLoadingPassword(false);
      return;
    }
    try {
      const result = await dispatch(
        handleUpdatePasswordThunk(
          currentPassword,
          newPassword,
          setpasswordErrorMessage
        )
      );

      // Check if the dispatch was successful
      if (result) {
        toast.success("Password updated successfully!");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      // Handle any other errors
    } finally {
      setIsLoadingPassword(false);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: "120px",
        marginRight: "120px",
        width: "100%",
      }}
    >
      <Toaster />
      <Container style={{ marginTop: "3rem" }}>
        <Row>
          <Col md={6}>
            <div
              style={{
                border: "1px solid #E2E8F0",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.5rem",
                padding: "2rem",
              }}
            >
              <h3
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#1A202C",
                  marginBottom: "1rem",
                }}
              >
                General Information
              </h3>
              <Form onSubmit={handleSubmitName}>
                <Form.Group className="mb-3">
                  <Form.Label
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "medium",
                      color: "#1A202C",
                    }}
                  >
                    Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={handleNameChange}
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #E2E8F0",
                      color: "#1A202C",
                      borderRadius: "0.375rem",
                      padding: "0.625rem",
                    }}
                  />
                </Form.Group>
                {isLoading ? (
                  <Button variant="primary" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.875rem",
                    }}
                  >
                    Save
                  </Button>
                )}
                {errorMessage && (
                  <Alert variant="danger" className="mt-3">
                    {errorMessage}
                  </Alert>
                )}
              </Form>
            </div>
          </Col>
          <Col md={6}>
            <div
              style={{
                border: "1px solid #E2E8F0",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.5rem",
                padding: "2rem",
                marginLeft: "0.5rem",
              }}
            >
              <h3
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#1A202C",
                  marginBottom: "1rem",
                }}
              >
                Password Information
              </h3>
              <Form onSubmit={handleSubmitPassword}>
                <Form.Group className="mb-3">
                  <Form.Label
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "medium",
                      color: "#1A202C",
                    }}
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={user.email}
                    style={{
                      backgroundColor: "#F3F4F6",
                      border: "1px solid #E2E8F0",
                      color: "#1A202C",
                      borderRadius: "0.375rem",
                      padding: "0.625rem",
                    }}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "medium",
                      color: "#1A202C",
                    }}
                  >
                    Current password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Current Password"
                    value={currentPassword}
                    onChange={handleCurrentPasswordChange}
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #E2E8F0",
                      color: "#1A202C",
                      borderRadius: "0.375rem",
                      padding: "0.625rem",
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "medium",
                      color: "#1A202C",
                    }}
                  >
                    New Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #E2E8F0",
                      color: "#1A202C",
                      borderRadius: "0.375rem",
                      padding: "0.625rem",
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "medium",
                      color: "#1A202C",
                    }}
                  >
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #E2E8F0",
                      color: "#1A202C",
                      borderRadius: "0.375rem",
                      padding: "0.625rem",
                    }}
                  />
                </Form.Group>
                <div
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: "medium",
                    color: "#1A202C",
                  }}
                >
                  Password requirements:
                </div>
                <div
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: "medium",
                    color: "#718096",
                    marginTop: "0.25rem",
                  }}
                >
                  Ensure that these requirements are met:
                </div>
                <div style={{ paddingLeft: "1rem" }}>
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "0.125rem",
                      fontSize: "0.75rem",
                      fontWeight: "normal",
                      color: "#718096",
                    }}
                  >
                    At least 10 characters (and up to 100 characters)
                  </div>
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "0.125rem",
                      fontSize: "0.75rem",
                      fontWeight: "normal",
                      color: "#718096",
                    }}
                  >
                    At least one lowercase character
                  </div>
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "0.125rem",
                      fontSize: "0.75rem",
                      fontWeight: "normal",
                      color: "#718096",
                    }}
                  >
                    Inclusion of at least one special character, e.g., ! @ # ?
                  </div>
                </div>
                {isLoadingPassword ? (
                  <Button variant="primary" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.875rem",
                      marginTop: "1rem",
                    }}
                  >
                    Save All
                  </Button>
                )}
                {passwordErrorMessage && (
                  <Alert variant="danger" className="mt-3">
                    {passwordErrorMessage}
                  </Alert>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserSettingsPage;
