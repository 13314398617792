import { useAppSelector } from "../../redux/hooks";
import { Breadcrumb, Button } from "react-bootstrap";
import { selectDatasetSettings } from "./redux/detasetSettingsSlice";
import {
  FETCHING_FIELDS_EVENT_KEY,
  selectFirstFieldByDatasetUuid,
} from "../fieldMetadata/redux/fieldSlice";
import { FixedSizeList as List } from "react-window";
import { useParams } from "react-router-dom";
import { AddFieldButton, FieldsRow } from "../fieldMetadata/FieldsRow";
import { DataRow } from "./DataRow";
import {
  ActionStatusType,
  selectStatusByEventKey,
} from "../actions/redux/actionSlice";
import { Spinner } from "react-bootstrap";
import { RtfInputModal } from "./RtfInputModal";
import NavBar from "../../pages/dashboard/NavBar";
import styles from "./DatasetTableContainer.module.css";
import Dataset_Empty_State from "../../assets/DataSet_emptyState.png";
import EmptyState from "./EmptyState";

export const DatasetTableContainer = (props: { datasetUuid: string }) => {
  const isEditorOpen = useAppSelector(
    (state) => selectDatasetSettings(state).isRuleEditorOpen
  );
  const firstField = useAppSelector((state) =>
    selectFirstFieldByDatasetUuid(state, props.datasetUuid)
  );

  const fetchFieldsActionStatus = useAppSelector((state) =>
    selectStatusByEventKey(state, FETCHING_FIELDS_EVENT_KEY)
  );
  const width = window.innerWidth * (isEditorOpen ? 0.7 : 1);

  const height = window.innerHeight * 1;
  if (fetchFieldsActionStatus?.type === ActionStatusType.PENDING) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  if (firstField == null) {
    return <EmptyState datasetUuid={props.datasetUuid} />;
  }
  return (
    <>
      <List height={height} itemCount={1000} itemSize={46} width={width}>
        {Row}
      </List>
      <RtfInputModal />
    </>
  );
};

const Row = (props: { index: any; style: any }) => {
  const param = useParams<{ uuid: string }>();
  const datasetUuid = param.uuid as string;
  if (props.index === 0) {
    return <FieldsRow key={props.index} datasetUuid={datasetUuid} />;
  }

  return (
    <DataRow
      key={props.index}
      datasetUuid={datasetUuid}
      index={props.index}
      style={props.style}
    />
  );
};
