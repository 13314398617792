import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ValidationErrors } from "../../services/rules/ErrorMessage";
import {
  selectField,
  updateFieldThunkFunction,
} from "../../pages/fieldMetadata/redux/fieldSlice";
import { DateConfig } from "../../services/rules/DateConfig";
import { RuleEditorProps } from "./ruleEditorPropsInterface";

export const DateRuleEditor = (props: RuleEditorProps) => {
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [dateFormat, setDateFormat] = useState<string>("dd/mm/yyyy");
  const [errors, setErrors] = useState<ValidationErrors[]>([]);
  const field = useAppSelector((state) => selectField(state, props.fieldUuid));
  const rule = field?.configStr ?? null;
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (!rule) {
      return;
    }
    const parsedRule = JSON.parse(rule);
    const config = DateConfig.fromJson(parsedRule);
    setIsRequired(config.isRequired);
    setDateFormat(config.dateFormat);
  }, [rule]);

  const handleSave = () => {
    if (field == null) {
      return;
    }
    const updatedConfig = new DateConfig(isRequired, dateFormat);
    try {
      const errors: Array<ValidationErrors> = updatedConfig.validateRules();
      if (errors.length > 0) {
        setErrors(errors);
        return;
      } else {
        setErrors([]);
      }
    } catch (e: any) {
      alert(`Failed to validate rules: ${e.message}`);
      return;
    }
    appDispatch(
      updateFieldThunkFunction({
        ...field,
        configStr: JSON.stringify(updatedConfig.toJson()),
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        width: "100%",
        height: "100%",
        backgroundColor: "lightgrey",
      }}
    >
      <table>
        <tr>
          <td>
            <label htmlFor="is-required">Is Required:</label>
          </td>
          <td>
            <input
              type="checkbox"
              id="is-required"
              checked={isRequired}
              onChange={(e) => setIsRequired(e.target.checked)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="date-format">Date Format:</label>
          </td>
          <td>
            <select
              id="date-format"
              value={dateFormat}
              onChange={(e) => setDateFormat(e.target.value)}
            >
              <option value="dd/mm/yyyy">dd/mm/yyyy or d/m/yyyy</option>
              <option value="mm-dd-yyyy">mm-dd-yyyy or m-d-yyyy</option>
              <option value="dd-mm-yyyy">dd-mm-yyyy or d-m-yyyy</option>
              <option value="mm/dd/yyyy">mm/dd/yyyy or m/d/yyyy</option>
            </select>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            {errors.map((error) => (
              <div
                key={error.message}
                style={{
                  color: "red",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {error.message}
              </div>
            ))}
          </td>
        </tr>
      </table>
      <button onClick={handleSave}>Save</button>
    </div>
  );
};
