import React from "react";

import "./LandingPage.css";
import { Button } from "react-bootstrap";
import logo from "../../assets/logo.png";
import { isValidEmail } from "../../utils";
import { handleJoinWaitlist } from "../auth/services/waitlistService";
import { useAppSelector } from "../../redux/hooks";
import { selectUser } from "../auth/redux/authSlice";
import { Navigate } from "react-router-dom";

export const LandingPage = () => {
  const user = useAppSelector((state) => selectUser(state));
  if (user) {
    return <Navigate to="/user/home" />;
  }
  return (
    <div className="landing-page">
      <img
        src={logo}
        alt="Datarox Logo"
        style={{
          width: "120px",
        }}
      />
      <header
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <h1 className="mb-3 fw-semibold lh-1">
          Simplify Data Entry, Customize Your Way
        </h1>
        <h2>
          Eliminate time and money wasted on complex data validation scripts
        </h2>
        <p className="lead mb-4" style={{ maxWidth: "800px" }}>
          Datapler combines the familiar UX of spreadsheets with intuitive form
          building. Create forms tailored to your needs – ensuring accurate data
          entry without sacrificing speed and enhanced ease of use.
        </p>
      </header>
      <WaitlistForm />
      <div
        style={{
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <section className="features">
          <h3>Key Features</h3>
          <ul>
            <li>Lightning-Fast Entry: Intuitive forms and smart shortcuts.</li>
            <li>
              Unmatched Accuracy: Built-in validation and error prevention.
            </li>
            <li>
              Flexible & Adaptable: Customize to fit your unique data needs.
            </li>
          </ul>
        </section>
      </div>

      <footer>
        <p>&copy; {new Date().getFullYear()} Datarox</p>
      </footer>
    </div>
  );
};

const WaitlistForm = (props: {}) => {
  const [email, setEmail] = React.useState("");
  const [waitlistSuccess, setWaitlistSuccess] = React.useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p>Join the waitlist to get early access and a 60-day free trial.</p>
      {waitlistSuccess ? (
        <div className="alert alert-success" role="alert">
          Thank you for joining the waitlist!
        </div>
      ) : (
        <>
          <div className="d-flex flex-column flex-lg-row align-items-md-stretch justify-content-md-center gap-3 mb-4">
            <div className="form-floating ">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  textAlign: "center",
                }}
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>
            <Button
              disabled={!isValidEmail(email)}
              onClick={() => {
                handleJoinWaitlist(email).then((success) => {
                  setWaitlistSuccess(success);
                });
              }}
            >
              Join Waitlist
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
