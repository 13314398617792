import React from "react";
import { Breadcrumb } from "react-bootstrap";
import NavBar from "../../pages/dashboard/NavBar";
import styles from "./DatasetTableContainer.module.css";
import DatasetEmptyStateImage from "../../assets/dataset_empty_state.png";
import { AddFieldButton } from "../fieldMetadata/FieldsRow";

const EmptyState = (props: { datasetUuid: string }) => {
  return (
    <div
      style={{
        width: "auto",
        height: "100vh",
        backgroundColor: "#E5E7EB",
        overflowX: "hidden",
      }}
    >
      <NavBar />
      <div className={styles.pageTitle}>
        <Breadcrumb>
          <Breadcrumb.Item href="" className={styles.customBreadcrumbItem}>
            Business Name
          </Breadcrumb.Item>
          <Breadcrumb.Item active className={styles.customBreadcrumbItem}>
            Dataset Name
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div>
        <div
          style={{
            width: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "start",
            height: "50px",
            paddingLeft: "160px",
            paddingTop: "80px",
          }}
        >
          <AddFieldButton datasetUuid={props.datasetUuid} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "170px",
          }}
        >
          <img src={DatasetEmptyStateImage} alt="emptystate"></img>
          <h4
            style={{
              fontFamily: "Inter",
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "29.05px",
              textAlign: "left",
              color: "rgba(119, 73, 248, 1)",
            }}
          >
            Add New Data Field
          </h4>
          <h6
            style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "19.36px",
              textAlign: "center",
              width: "400px",
            }}
          >
            You haven't added any data field yet. Click on the add new field
            button to add a new data field collam
          </h6>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
