import { Dispatch, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";
import { DatasetService } from "../services/dataset.service";
import { DataFieldService } from "../services/dataField.service";
import {
  addFailedActionStatus,
  addPendingActionStatus,
  addSuccessfulActionStatus,
  clearActionStatusByKey,
} from "../../actions/redux/actionSlice";
import { RtfActions } from "./actions/rtfActions";
import { selectUser, selectUserUid } from "pages/auth/redux/authSlice";

export interface TCellData {
  fieldUuid: string;
  row: number;
  value: string;
  datasetUuid: string;
}

export interface DataState {
  data: {
    [fieldUuid: string]: {
      [row: number]: {
        value: string;
        datasetUuid: string;
      };
    };
  };
  editQueue: Array<TCellData>;
  isBatchRequestPending: boolean;
}

const initialState: DataState = {
  data: {},
  editQueue: [],
  isBatchRequestPending: false,
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    upsertData: (state, action: PayloadAction<TCellData>) => {
      const { fieldUuid, row, value } = action.payload;
      if (!state.data[fieldUuid]) {
        state.data[fieldUuid] = {};
      }
      state.data[fieldUuid][row] = {
        value,
        datasetUuid: action.payload.datasetUuid,
      };
      if (
        !state?.editQueue?.find(
          (d) => d.fieldUuid === fieldUuid && d.row === row
        )
      ) {
        state.editQueue.push(action.payload);
      } else {
        state.editQueue = state.editQueue.map((d) =>
          d.fieldUuid === fieldUuid && d.row === row ? action.payload : d
        );
      }
    },

    _updateDataBatch: (
      state,
      action: PayloadAction<{
        data: Array<{
          fieldUuid: string;
          row: number;
          value: string;
        }>;
      }>
    ) => {
      const updates = action.payload;
      const { data } = updates;

      data.forEach((d) => {
        const { fieldUuid, row } = d;
        const value = d.value;
        if (!fieldUuid || !row) {
          throw new Error("Invalid data received from server");
        }
        if (!state.data[fieldUuid]) {
          state.data[fieldUuid] = {};
        }
        state.data[fieldUuid][row] = {
          value,
          datasetUuid: state.data[fieldUuid][row]?.datasetUuid,
        };
      });
    },
    deleteRow: (state, action: PayloadAction<number>) => {
      // TODO(Taman): Implement this
    },
    clearEditQueue: (state, action: PayloadAction<void>) => {
      state.editQueue = [];
    },
    setIsBatchRequestPending: (state, action: PayloadAction<boolean>) => {
      state.isBatchRequestPending = action.payload;
    },

    _initDataset: (
      state,
      action: PayloadAction<{ data: Array<TCellData>; datasetUuid: string }>
    ) => {
      if (action.payload == null) {
        return;
      }
      const data = action.payload.data.reduce((acc, d) => {
        if (!acc[d.fieldUuid]) {
          acc[d.fieldUuid] = {};
        }
        acc[d.fieldUuid][d.row] = {
          value: d.value,
          datasetUuid: action.payload.datasetUuid,
        };
        return acc;
      }, {} as DataState["data"]);
      return {
        ...state,
        data,
      };
    },
  },
});

export const {
  upsertData,
  clearEditQueue,
  setIsBatchRequestPending,
  _updateDataBatch,
  _initDataset,
} = dataSlice.actions;

export const selectEditQueue = (state: RootState) => state.data.editQueue;

var changeGate = false;
export const upsertDataThunkFunction = (data: TCellData): any => {
  return (dispatch: Dispatch, getState: any): void => {
    dispatch(upsertData(data));

    if (!changeGate && getState().data.editQueue.length) {
      changeGate = true;
      setIsBatchRequestPending(true);
      setTimeout(async (): Promise<void> => {
        await new DatasetService().handleFieldUpdateEvents(
          getState().data.editQueue,
          selectUser(getState()).uuid
        );
        setIsBatchRequestPending(false);
        changeGate = false;
        dispatch(clearEditQueue());
      }, DatasetService.REMOTE_UPDATE_INTERVAL);
    }
  };
};

export const upsertRtfDataThunkFunction = (data: TCellData): any => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    dispatch(addPendingActionStatus({ key: RtfActions.UPDATE_RTF_VALUE }));
    const user = selectUser(getState());
    try {
      // handleFieldUpdateEvents expects an array of TCellData
      await new DatasetService().handleFieldUpdateEvents([data], user.uuid);

      // FIX_ME: This is a hack to update the UI with the new value.
      // This should be fetched from the server for RTF as these values might be too large.
      dispatch(upsertData(data));
      dispatch(
        addSuccessfulActionStatus({
          key: RtfActions.UPDATE_RTF_VALUE,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: RtfActions.UPDATE_RTF_VALUE,
          message: e.message ?? "Failed to update data",
        })
      );
    }
  };
};

export const INIT_DATASET_FIELDS_ACTION_EVENT = "INIT_DATASET_FIELDS_ACTION";

export const initDatasetThunkFunction = (datasetUuid: string): any => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(addPendingActionStatus({ key: INIT_DATASET_FIELDS_ACTION_EVENT }));
    try {
      const data = await new DataFieldService().getDataByDatasetX(datasetUuid);
      dispatch(_initDataset({ data, datasetUuid }));
      dispatch(
        clearActionStatusByKey({ key: INIT_DATASET_FIELDS_ACTION_EVENT })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: INIT_DATASET_FIELDS_ACTION_EVENT,
          message: e.message ?? "Failed to fetch data",
        })
      );
    }
  };
};

export const updateDataBatchThunk = (payload: {
  data: Array<{
    fieldUuid: string;
    row: number;
    value: string;
  }>;
  userUuid: string;
}): any => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const userUuid = selectUserUid(getState());
    // TODO(Taman / critical): fix backend to send the userUuid in the payload
    if (userUuid === payload.userUuid) {
      // Don't apply the updates for the user who initiated the change
      console.log("Ignoring update for user who initiated the change");
      return;
    }

    _updateDataBatch({ data: payload.data });
  };
};
