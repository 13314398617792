import { useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  BusinessRoleInvitationStatusToString,
  BusinessRoleToString,
} from "../business.model";
import {
  BusinessActionStatusKey,
  BusinessUser,
  removeUserFromBusinessThunk,
  selectBusinessRoles,
} from ".././redux/businessUserSlice";
import {
  ActionStatusType,
  selectStatusByEventKey,
} from "../../actions/redux/actionSlice";
import { RoleCreationModal } from "./RoleCreationModal";
import { RoleEditModal } from "./RoleEditModal";
import { DeletionModal } from "../../components/DeletionModal";

export const BusinessUserRoles = (props: { businessUuid: string }) => {
  const roles = useAppSelector((state) =>
    selectBusinessRoles(state, props.businessUuid)
  );
  const loadingStatus = useAppSelector((state) =>
    selectStatusByEventKey(
      state,
      BusinessActionStatusKey.FETCH_BUSINESS_USER_ROLE
    )
  );
  const [showRoleCreationModal, setShowRoleCreationModal] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<BusinessUser>();
  const appDispatch = useAppDispatch();

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [userToRemove, setUserToRemove] = useState<BusinessUser | null>(null);

  const handleDeleteConfirm = () => {
    if (userToRemove) {
      appDispatch(
        removeUserFromBusinessThunk(props.businessUuid, userToRemove.userUuid)
      );
    }
    setShowDeleteConfirmation(false);
    setUserToRemove(null);
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
    setUserToRemove(null);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h2
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#1A202C",
            marginBottom: "1rem",
          }}
        >
          Business User Roles
        </h2>
        <Button
          variant="primary"
          style={{
            marginLeft: "100px",
            marginRight: "20px",
          }}
          onClick={() => {
            setShowRoleCreationModal(true);
          }}
        >
          Add User
        </Button>
      </div>

      <Table responsive className="table table-hover">
        <thead>
          <tr>
            <th>User</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody
          style={{
            width: "100%",
          }}
        >
          {loadingStatus?.type === ActionStatusType.PENDING ? (
            <Spinner />
          ) : (
            roles.map((role: BusinessUser) => (
              <tr key={role.userUuid} style={{}}>
                <td>{role.displayName}</td>
                <td>{BusinessRoleToString(role.role)}</td>
                <td>
                  {BusinessRoleInvitationStatusToString(role.invitationStatus)}
                </td>
                <td>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setSelectedRole(role);
                      setShowEditRoleModal(true);
                    }}
                  >
                    <i className="bi bi-pencil-square" />
                  </Button>
                  <Button
                    style={{
                      marginLeft: "8px",
                    }}
                    variant="outline-danger"
                    onClick={() => {
                      setUserToRemove(role);
                      setShowDeleteConfirmation(true);
                    }}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <RoleCreationModal
        businessUuid={props.businessUuid}
        show={showRoleCreationModal}
        onHide={function (): void {
          setShowRoleCreationModal(false);
        }}
      />
      {selectedRole != null ? (
        <RoleEditModal
          show={showEditRoleModal}
          onHide={() => setShowEditRoleModal(false)}
          defaultBusinessUser={selectedRole}
        />
      ) : null}

      <DeletionModal
        show={showDeleteConfirmation}
        onHide={handleDeleteCancel}
        deletionMessage={`Are you sure you want to remove the user "${userToRemove?.displayName}"`}
        onDelete={handleDeleteConfirm}
      />
    </div>
  );
};
