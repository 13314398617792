import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../redux/store";

export const datroxPages = {
  home: "home",
  userSettings: "settings",
  userRoles: "roles",
  none: "",
};

export type DatroxPage = keyof typeof datroxPages;

const initialState = {
  activePath: datroxPages.none,
};

export const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setActiveRoute: (state, action: PayloadAction<DatroxPage>) => {
      state.activePath = action.payload;
    },
  },
});

export const { setActiveRoute } = routeSlice.actions;

export const selectActivePath = (state: RootState) => state.route.activePath;
