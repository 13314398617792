import { Offcanvas } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectIsCellEditorOpen,
  toggleCellEditor,
} from "../redux/detasetSettingsSlice";
import { selectField } from "../../fieldMetadata/redux/fieldSlice";
import { SanitizerEditor } from "./SanitizerEditor";
import { CellValidator } from "./CellValidator";

export const CellEditorContainer = () => {
  const isEditorOpen = useAppSelector((state) => selectIsCellEditorOpen(state));

  const appDispatch = useAppDispatch();
  const activeCell = useAppSelector((state) => state.activeCell);
  const field = useAppSelector((state) =>
    selectField(state, activeCell.cell.fieldUuid!)
  );

  return (
    <Offcanvas
      show={isEditorOpen}
      onHide={() => {
        appDispatch(toggleCellEditor(false));
      }}
      backdrop={false}
      scroll={true}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{field?.name || ""}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div>
          <CellValidator />
          <div style={{ marginTop: 10 }}></div>
          <SanitizerEditor />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
