import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateEmail,
  updatePassword,
  updateProfile,
  verifyPasswordResetCode,
} from "firebase/auth";

import {
  CustomHeaders,
  type TCustomHeader,
} from "../../../services/customHeaders";

import {
  genUrl,
  validateEmail,
  validatePassword,
  validateUserFullname,
} from "./utils";

import type { User, UserCredential } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

import { firebaseAuth } from "../../../services/firebaseAppService";

const analytics = getAnalytics();

let unsubscribeFromFirebaseAuthStateChange = () => {};

export function sendPasswordResetEmailService(payload: { email: string }) {
  const { email } = payload;
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(firebaseAuth, email)
      .then(function () {
        resolve(true);
      })
      .catch(function (error) {
        switch (error.code) {
          case "auth/invalid-email":
            reject({ message: "Invalid e-mail address format" });
            break;
          case "auth/user-disabled":
            reject({ message: "This account has been disabled" });
            break;
          case "auth/user-not-found":
            reject({ message: "This account does not exist" });
            break;
          default:
            reject({
              message: error?.message ?? "Check your internet connection",
            });
        }
        return;
      });
  });
}

export const resetPasswordService = async (payload: {
  password: string;
  oobCode: string;
}) => {
  const { password, oobCode } = payload;
  try {
    const email = await verifyPasswordResetCode(firebaseAuth, oobCode);
    if (email) {
      await confirmPasswordReset(firebaseAuth, oobCode, password);
      return { success: true };
    } else {
      throw new Error("Incorrect URL. Please try again from your email");
    }
  } catch (error: any) {
    let errorMessage;
    switch (error.code) {
      case "auth/expired-action-code":
        errorMessage =
          "This link has expired. Please try again from Forgot Password.";
        break;
      case "auth/invalid-action-code":
        errorMessage = "Incorrect URL. Please try again from your email";
        break;
      case "auth/user-disabled":
        errorMessage = "This account has been disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "This account does not exist";
        break;
      case "auth/weak-password":
        errorMessage = "Password is too weak";
        break;
      default:
        errorMessage = error?.message ?? "Please try again later";
    }
    throw new Error(errorMessage);
  }
};

export function createFirebaseAccount(
  email: string,
  password: string,
  name: string
): Promise<{ user: User }> {
  return new Promise((resolve, reject) => {
    if (!validateUserFullname(name)) {
      throw new Error(
        "Invalid name. Name needs to be between 6 and 50 characters"
      );
    }
    createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then((userCredential) => {
        // Signed in
        const user: User = userCredential.user;
        updateUserFullname({ name: name });
        sendEmailVerification(user);
        // register user to Jhotika's database
        user
          .getIdToken()
          .then((idToken: string) => {
            registerUser(new CustomHeaders(idToken).getHeader()).catch(
              (error) => {
                logEvent(analytics, "Error registering user to DB", {
                  error: error,
                  uuid: user.uid,
                });
              }
            );
          })
          .then(() => {
            resolve({ user: user });
          })
          .catch((error) => {
            reject({ message: error.message });
          });
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            reject({ message: "This email address is already taken" });
            break;
          case "auth/invalid-email":
            reject({ message: "Invalid e-mail address format" });
            break;
          case "auth/weak-password":
            reject({ message: "Password is too weak" });
            break;
          default:
            reject({
              message: error?.message ?? "Check your internet connection",
            });
        }
      });
  });
}

export function signInWithGoogleService() {
  return new Promise((resolve, reject) => {
    const provider = new GoogleAuthProvider();
    provider.addScope("email");
    provider.addScope("profile");
    signInWithPopup(firebaseAuth, provider)
      .then((result) => {
        resolve({ user: result.user });
      })
      .catch((error) => {
        reject({ message: error.message });
      });

    // The signed-in user info.
  });
}

export const signInWithEmailService = async (
  email: string,
  password: string
): Promise<User> => {
  if (!validateEmail(email)) {
    throw new Error("Invalid email address format");
  }
  if (!validatePassword(password)) {
    throw new Error("Password must be at least 6 characters long");
  }
  try {
    const userCred: UserCredential = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    const user: User | null = userCred?.user;
    if (user == null) {
      throw new Error("Incorrect email or password");
    }
    if (user?.emailVerified === true) {
      return user;
    } else {
      await sendEmailVerification(user);
      throw new Error(
        "Email not verified. Please check your inbox for email verification link"
      );
    }
  } catch (error: any) {
    let errorMessage: string;
    switch (error.code) {
      case "auth/invalid-email":
        errorMessage = "Invalid e-mail address format";
        break;
      case "auth/user-disabled":
        errorMessage = "This account has been disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "This account does not exist";
        break;
      case "auth/missing-password":
      case "auth/missing-email":
      case "auth/wrong-password":
        errorMessage = "Incorrect email or password";
        break;
      case "auth/too-many-requests":
        errorMessage = "Too many requests. Try again later";
        break;
      default:
        errorMessage = error.message ?? "Check your internet connection";
    }
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw new Error(errorMessage);
  }
};

export const signOutService = async () => {
  unsubscribeFromFirebaseAuthStateChange();
  await signOut(firebaseAuth).catch((error) => {
    logEvent(analytics, "Error signing out", {
      error: error,
      uid: firebaseAuth?.currentUser?.uid,
    });

    throw new Error(error.message);
  });
};

// Utils

export function getCurUser(): Promise<User | null> {
  return new Promise((resolve) => {
    const user = firebaseAuth.currentUser;
    if (user) {
      resolve(user);
    } else {
      unsubscribeFromFirebaseAuthStateChange = firebaseAuth.onAuthStateChanged(
        (user) => {
          resolve(user || null);
        }
      );
    }
  });
}

export function fetchIdTokenAndExecute(fn: any, payload: any) {
  return new Promise((resolve) => {
    getCurUser()
      .then((user: User | null) => {
        if (user) {
          user
            .getIdToken(false)
            .then((idToken) => {
              resolve(
                fn(payload, new CustomHeaders(idToken).getHeader(), user.uid)
              );
            })
            .catch((error) => {
              resolve(fn(payload, null));
            });
        } else {
          resolve(fn(payload, null));
        }
      })
      .catch((error) => {
        resolve(fn(payload, null));
      });
  });
}

// FIX_ME: Better type for payload
export const updateDatroxProfile = (payload: any, headers: TCustomHeader) => {
  return new Promise((resolve, reject) => {
    fetch(genUrl("users"), {
      method: "PATCH",
      ...headers,
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((json) => {
            reject({ message: json.error });
          });
        }
      })
      .then((json) => {
        resolve(json);
        return;
      })
      .catch((error) => {
        reject({ message: error.message });
      });
  });
};

export const registerUser = async (headers: TCustomHeader) => {
  await fetch(genUrl("users"), {
    method: "POST",
    ...headers,
  });
};

export const updateUserPassword = async (
  oldPassword: string,
  newPassword: string,
  handleLoading: () => {},
  handleSuccess: () => {},
  handleError: (error: string) => {}
) => {
  if (firebaseAuth?.currentUser?.email == null) {
    handleError("User not found");
    return;
  }

  const credential = EmailAuthProvider.credential(
    firebaseAuth.currentUser.email,
    oldPassword
  );

  const userCred = await reauthenticateWithCredential(
    firebaseAuth.currentUser!,
    credential
  ).catch((error) => {
    switch (error.code) {
      case "auth/invalid-email":
        handleError("Invalid e-mail address format");
        break;
      case "auth/user-disabled":
        handleError("This account has been disabled");
        break;
      case "auth/user-not-found":
        handleError("This account does not exist");
        break;
      case "auth/wrong-password":
        handleError("Incorrect password");
        break;
      case "auth/too-many-requests":
        handleError("Too many requests. Try again later");
        break;
      default:
        handleError(error.message);
        break;
    }
  });

  if (userCred?.user == null) {
    handleError("Incorrect password");
    return;
  }

  return new Promise((resolve) => {
    updatePassword(firebaseAuth.currentUser!, newPassword)
      .then(() => {
        handleSuccess();
        resolve({ success: true });
        return;
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/weak-password":
            handleError("Password is too weak");
            break;
          default:
            handleError("Check your internet connection");
        }
      });
  });
};

// This is a special method that doesn't depend on Saga-Redux flow
export const updateUserEmail = async (payload: {
  newEmail: string;
  password: string;
}): Promise<User> => {
  if (firebaseAuth?.currentUser?.email == null) {
    throw new Error("User not found");
  }
  const { newEmail, password } = payload;
  try {
    await reauthenticateWithCredential(
      firebaseAuth.currentUser,
      EmailAuthProvider.credential(firebaseAuth.currentUser.email!, password)
    ).catch((error) => {
      let message;
      switch (error.code) {
        case "auth/invalid-email":
          message = "Invalid e-mail address format";
          break;
        case "auth/user-disabled":
          message = "This account has been disabled.";
          break;
        case "auth/wrong-password":
        case "auth/missing-password":
          message = "Incorrect password. Try again";
          break;
        case "auth/too-many-requests":
          message = "Too many requests. Try again later";
          break;
        default:
          message = error.message;
      }
      throw new Error(message);
    });
  } catch (error: any) {
    throw new Error(error.message ?? error);
  }
  return new Promise((resolve, reject) => {
    updateEmail(firebaseAuth.currentUser!, newEmail)
      .then(() => {
        sendEmailVerification(firebaseAuth.currentUser!).then(() => {
          resolve(firebaseAuth.currentUser!);
        });
        return;
      })
      .catch((error) => {
        let errorMessage;
        switch (error.code) {
          case "auth/invalid-email":
            errorMessage = "Invalid e-mail address format";
            break;
          case "auth/email-already-in-use":
            errorMessage = "This email address is already taken";
            break;
          default:
            errorMessage = error?.message ?? "Check your internet connection";
            break;
        }
        reject({ message: errorMessage });
        return;
      });
  });
};

export const updateUserFullname = (payload: { name: string }) => {
  const { name } = payload || {};
  return new Promise((resolve, reject) => {
    if (name == null) {
      throw new Error("Name cannot be empty");
    }
    if (firebaseAuth.currentUser == null) {
      throw new Error("User not signed in");
    }
    updateProfile(firebaseAuth.currentUser, {
      displayName: name,
    })
      .then(() => {
        resolve(firebaseAuth.currentUser);
        return;
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-display-name":
            reject({ message: "Invalid display name" });
            break;
          case "auth/user-disabled":
            reject({ message: "This account has been disabled" });
            break;
          case "auth/user-not-found":
            reject({ message: "This account does not exist" });
            break;
          default:
            reject({
              message: error?.message ?? "Check your internet connection",
            });
        }
        return;
      });
  });
};

// @throws
export const verifyEmail = async (payload: {
  oobCode: string;
}): Promise<{
  success: boolean;
}> => {
  const { oobCode } = payload;
  try {
    const email = await verifyPasswordResetCode(firebaseAuth, oobCode);
    if (email) {
      await applyActionCode(firebaseAuth, oobCode);
      return { success: true };
    } else {
      throw new Error("Incorrect URL. Please try again from your email");
    }
  } catch (error: any) {
    let errorMessage;
    switch (error.code) {
      case "auth/brea-action-code":
        errorMessage = error.message;
        break;
      case "auth/expired-action-code":
        errorMessage =
          "This link has expired. Please try again from Forgot Password.";
        break;
      case "auth/invalid-action-code":
        errorMessage = "Incorrect URL. Please try again from your email";
        break;
      case "auth/user-disabled":
        errorMessage = "This account has been disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "This account does not exist";
        break;
      case "auth/weak-password":
        errorMessage = "Password is too weak";
        break;
      default:
        errorMessage = "Please try again later";
    }
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw new Error(errorMessage);
  }
};

export const deleteAccount = async (
  payload: {
    email: string;
    password: string;
  },
  headers: TCustomHeader
) => {
  if (!firebaseAuth.currentUser) {
    throw new Error("User not logged in");
  }
  await genReauthenticateUser(
    firebaseAuth.currentUser!,
    payload.email,
    payload.password
  );
  const response = await fetch(genUrl("users/deleteAccount"), {
    method: "POST",
    body: JSON.stringify({}),
    ...headers,
  });
  if (response.ok) {
    await signOutService();
  } else {
    throw new Error("Something went wrong. Please try again later");
  }
};

export const recoverEmail = async (payload: {
  oobCode: string;
}): Promise<{
  restoredEmail: string;
}> => {
  const { oobCode } = payload;
  try {
    const info = await checkActionCode(firebaseAuth, oobCode);
    if (!info) {
      throw new Error("Incorrect URL. Please try again from your email");
    }
    const restoredEmail = info?.data?.email;
    if (!restoredEmail) {
      throw new Error("Incorrect URL. Please try again from your email");
    }
    await applyActionCode(firebaseAuth, oobCode);
    await sendPasswordResetEmail(firebaseAuth, restoredEmail);
    return { restoredEmail: restoredEmail };
  } catch (error: any) {
    let errorMessage;
    switch (error.code) {
      case "auth/brea-action-code":
        errorMessage = error.message;
        break;
      case "auth/email-already-in-use":
        errorMessage = "This email address is already in use. Try signing in";
        break;
      case "auth/expired-action-code":
        errorMessage =
          "This link has expired. Please try again from Forgot Password.";
        break;
      case "auth/invalid-action-code":
        errorMessage = "Incorrect URL. Please try again from your email";
        break;
      case "auth/user-disabled":
        errorMessage = "This account has been disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "This account does not exist";
        break;
      default:
        errorMessage = "Please try again later";
    }
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
    throw new Error(errorMessage);
  }
};

export const genReauthenticateUser = async (
  currentUser: User,
  email: string,
  password: string
) => {
  const credential = EmailAuthProvider.credential(email, password);
  const userCred: UserCredential = await reauthenticateWithCredential(
    currentUser,
    credential
  ).catch((error: any) => {
    let message;
    switch (error.code) {
      case "auth/invalid-email":
        message = "Invalid e-mail address format";
        break;
      case "auth/user-disabled":
        message = "This account has been disabled.";
        break;
      case "auth/wrong-password":
      case "auth/missing-password":
        message = "Incorrect password. Try again";
        break;
      case "auth/too-many-requests":
        message = "Too many requests. Try again later";
        break;
      default:
        message = error.message;
    }
    throw new Error(message);
  });

  return userCred.user;
};
