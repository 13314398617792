import { TokenType, TokenTypes } from "./TokenType";
import { AbstractTokenConfig } from "./AbstractTokenConfig";
import { ValidationErrors } from "./ErrorMessage";

interface UrlConfigData {
  type: TokenType;
  maxLength: number;
  isRequired: boolean;
}

export class UrlConfig extends AbstractTokenConfig {
  static __type: TokenType = TokenTypes.url as TokenType;
  static DEFAULT_MAX_LENGTH = 2048; // Example default max length for URLs

  public constructor(
    public maxLength: number = UrlConfig.DEFAULT_MAX_LENGTH,
    public isRequired: boolean = false
  ) {
    super(UrlConfig.__type as TokenType);
  }

  public static fromJson(data: UrlConfigData): UrlConfig {
    return new UrlConfig(data.maxLength, data.isRequired);
  }

  public toJson(): UrlConfigData {
    return {
      type: UrlConfig.__type,
      maxLength: this.maxLength,
      isRequired: this.isRequired,
    };
  }

  public copyWith(
    maxLength: number | undefined,
    isRequired: boolean | undefined
  ): UrlConfig {
    return new UrlConfig(
      maxLength === undefined ? this.maxLength : maxLength,
      isRequired === undefined ? this.isRequired : isRequired
    );
  }

  public validateRules(): Array<ValidationErrors> {
    if (this.maxLength <= 0) {
      return [
        {
          isValid: false,
          message: "Max length must be greater than 0",
        },
      ];
    }
    return [];
  }

  public validateInput(input: string): Array<ValidationErrors> {
    if (input === "" && this.isRequired) {
      return [{ isValid: false, message: "Input is required" }];
    }
    if (input.length > this.maxLength) {
      return [
        {
          isValid: false,
          message: `Input length must be less than ${this.maxLength}`,
        },
      ];
    }
    const urlRegex = /^(https?:\/\/)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.(?:[a-zA-Z]{2,63}|xn--[a-zA-Z0-9]{2,59}))(:\d+)?([\-a-zA-Z0-9@:%_+.~#?&/=]*)$/ ;
    if (!urlRegex.test(input)) {
      return [{ isValid: false, message: "Invalid URL format" }];
    }
    return [];
  }
}
