import { useAppSelector } from "../../redux/hooks";
import { useEffect, useState } from "react";
import { FieldTypes, selectField } from "../fieldMetadata/redux/fieldSlice";
import { ValidationErrors } from "../../services/rules/ErrorMessage";
import { selectActiveCell } from "./redux/activeCellSlice";

import { ruleConfigFactory } from "../../services/rules/RuleConfigFactory";
import { selectValueForCell } from "./redux/selectors/cellValue.selector";
import { InlineInputCell } from "./InlineInputCell";
import { RichTextCell } from "./RichTextCell";

interface InputCellProps {
  datasetUuid: string;
  row: number;
  fieldUuid: string;
}

export const InputCellContainer = (
  props: InputCellProps
): React.ReactElement => {
  const activeCell = useAppSelector((state) => selectActiveCell(state));

  const field = useAppSelector((state) => selectField(state, props.fieldUuid));

  const existingValue = useAppSelector((state) =>
    selectValueForCell(state, props.fieldUuid, props.row)
  );
  const [value, setValue] = useState(existingValue ?? "");
  const [inputError, setInputError] = useState<string | null>(null);

  useEffect(() => {
    setValue(existingValue ?? "");
  }, [existingValue]);

  useEffect(() => {
    const rule = field?.configStr;
    if (field == null || rule == null) {
      return;
    }
    var parsedRule: string | null = null;
    try {
      if (rule !== "") {
        parsedRule = JSON.parse(rule);
      }
    } catch (e: any) {
      // FIX_ME: Add logging
      alert(`Failed to parse rule: ${e}`);
      return;
    }

    if (parsedRule == null) {
      return;
    }
    const _config = ruleConfigFactory.createConfig(field.type, parsedRule);
    const validationErrors: Array<ValidationErrors> | undefined =
      _config?.validateInput(value);
    if (validationErrors?.length === 0) {
      setInputError(null);
    } else {
      setInputError(validationErrors[0].message);
    }
  }, [field, value]);

  const isActive =
    activeCell.cell.row === props.row &&
    activeCell.cell.fieldUuid === props.fieldUuid;

  return field?.type === FieldTypes.rich_text ? (
    <RichTextCell
      datasetUuid={props.datasetUuid}
      row={props.row}
      fieldUuid={props.fieldUuid}
      isActive={isActive}
    />
  ) : (
    <InlineInputCell
      datasetUuid={props.datasetUuid}
      row={props.row}
      fieldUuid={props.fieldUuid}
      isActive={isActive}
      isValid={inputError === null}
      value={value}
      setValue={setValue}
    />
  );
};
