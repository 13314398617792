import {
  Dispatch,
  PayloadAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { selectFirstFieldByDatasetUuid } from "../../../pages/fieldMetadata/redux/fieldSlice";
import { RootState } from "../../../redux/store";

export interface ActiveCell {
  datasetUuid: string | null;
  row: number;
  fieldUuid: string | null; // column
}

interface ActiveCellState {
  cell: ActiveCell;
}

export const CellTypes = {
  METADATA: "metadata",
  DATA: "data",
};

export type CellType = (typeof CellTypes)[keyof typeof CellTypes];

const initialState: ActiveCellState = {
  cell: {
    datasetUuid: null,
    row: 0,
    fieldUuid: null,
  } as ActiveCell,
};

export const activeCellSlice = createSlice({
  name: "activeCell",
  initialState,
  reducers: {
    setActiveCell: (state, action: PayloadAction<ActiveCell>) => {
      state.cell = action.payload;
    },
  },
});

export const { setActiveCell } = activeCellSlice.actions;

const selectActiveCellUuid = (state: RootState) => state.activeCell;

export const selectActiveCell = createSelector(
  [selectActiveCellUuid],
  (activeCell) => ({
    cell: activeCell.cell,
    cellType: activeCell?.cell?.row === 0 ? CellTypes.METADATA : CellTypes.DATA,
  })
);

export const initActiveCellForDataset =
  (datasetUuid: string): any =>
  async (dispatch: Dispatch, getState: any): Promise<any> => {
    const firstField = selectFirstFieldByDatasetUuid(getState(), datasetUuid);
    dispatch(
      setActiveCell({
        datasetUuid: datasetUuid,
        row: 0,
        fieldUuid: firstField?.uuid ?? "",
      })
    );
  };
