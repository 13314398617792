import { TokenType, TokenTypes } from "./TokenType";
import { AbstractTokenConfig, TokenConfigData } from "./AbstractTokenConfig";
import { ValidationErrors } from "./ErrorMessage";

interface ListConfigData extends TokenConfigData {
  maxLength: number;
  minLength: number;
  seperator: string;
  allowDuplicates: boolean;
}

export class ListConfig extends AbstractTokenConfig {
  static __type: TokenType = TokenTypes.list as TokenType;
  static UPPER_LIMIT = 2500; // approximately 10 KB including tags. Assuming a factor of 3 bytes of tags per character
  static DEFAULT_MIN_LENGTH = 10;
  static DEFAULT_MAX_LENGTH = 2500;
  static DEFAULT_SEPARATOR = ",";

  public constructor(
    public minLength: number = ListConfig.DEFAULT_MIN_LENGTH,
    public maxLength: number = ListConfig.DEFAULT_MAX_LENGTH,
    public seperator: string = ListConfig.DEFAULT_SEPARATOR,
    public allowDuplicates: boolean = false,
    public isRequired: boolean = false
  ) {
    super(ListConfig.__type as TokenType);
  }

  public static fromJson(data: ListConfigData): ListConfig {
    return new ListConfig(
      data.minLength,
      data.maxLength,
      data.seperator,
      data.allowDuplicates,
      data.isRequired
    );
  }

  public toJson(): ListConfigData {
    return {
      type: ListConfig.__type,
      minLength: this.minLength,
      maxLength: this.maxLength,
      seperator: this.seperator,
      allowDuplicates: this.allowDuplicates,
      isRequired: this.isRequired,
    };
  }

  public copyWith(
    maxLength: number | undefined,
    minLength: number | undefined,
    seperator: string | undefined,
    allowDuplicates: boolean | undefined,
    isRequired: boolean | undefined
  ): ListConfig {
    return new ListConfig(
      minLength === undefined ? this.minLength : minLength,
      maxLength === undefined ? this.maxLength : maxLength,
      seperator === undefined ? this.seperator : seperator,
      allowDuplicates === undefined ? this.allowDuplicates : allowDuplicates,
      isRequired === undefined ? this.isRequired : isRequired
    );
  }

  public validateRules(): Array<ValidationErrors> {
    if (Math.min(this.maxLength, this.minLength) < 0) {
      return [
        {
          isValid: false,
          message: "Length can't be negative",
        },
      ];
    }
    if (this.minLength > this.maxLength) {
      return [
        {
          isValid: false,
          message: "Min length can't be greater than max length",
        },
      ];
    }
    if (!this.seperator) {
      return [
        {
          isValid: false,
          message: "Seperator can't be empty",
        },
      ];
    }

    if (this.maxLength === 0 && this.isRequired) {
      return [
        {
          isValid: false,
          message: "Max length can't be 0 if input is required",
        },
      ];
    }
    if (this.maxLength > ListConfig.UPPER_LIMIT) {
      return [
        {
          isValid: false,
          message: `Max length must be less than ${ListConfig.UPPER_LIMIT}`,
        },
      ];
    }
    return [];
  }

  public validateInput(_input: string): Array<ValidationErrors> {
    if (!_input) {
      if (this.isRequired) {
        return [{ isValid: false, message: "Input is required" }];
      }
      return [];
    }

    const list = _input
      .split(this.seperator)
      .filter((item) => item.trim() !== "");
    if (list.length > ListConfig.UPPER_LIMIT) {
      return [
        {
          isValid: false,
          message: `List length must be less than ${ListConfig.UPPER_LIMIT}. Current length: ${list.length}`,
        },
      ];
    }
    if (list.length < this.minLength) {
      return [
        {
          isValid: false,
          message: `List length must be greater than ${this.minLength}. Current length: ${list.length}`,
        },
      ];
    }
    if (list.length > this.maxLength) {
      return [
        {
          isValid: false,
          message: `List length must be less than ${this.maxLength}. Current length: ${list.length}`,
        },
      ];
    }

    if (!this.allowDuplicates) {
      const uniqueList = new Set(list);
      if (uniqueList.size !== list.length) {
        return [
          {
            isValid: false,
            message: "List contains duplicates",
          },
        ];
      }
    }
    return [];
  }

  public sanitize = (input: string): string => {
    const trimmed = (input ?? "")
      .trim()
      .split(this.seperator)
      .filter((item) => item != null && item.trim() !== "")
      .map((item) => item.trim())
      .join(this.seperator);
    if (this.allowDuplicates) {
      return trimmed;
    }
    const uniqueList = new Set(trimmed.split(this.seperator));
    return Array.from(uniqueList).join(this.seperator);
  };
}
