import React from "react";

import { useDispatch } from "react-redux";

import { DateRuleEditor } from "./DateRuleEditor";
import { EmailRuleEditor } from "./EmailRuleEditor";
import { JsonRuleEditor } from "./JsonRuleEditor";
import { ListRuleEditor } from "./ListRuleEditor";
import { NumberRuleEditor } from "./NumberRuleEditor";
import { RichTextRuleEditor } from "./RichTextRuleEditor";
import { TextRuleEditor } from "./TextRuleEditor";
import { UrlRuleEditor } from "./UrlRuleEditor";

import {
  selectField,
  type FieldType,
  FieldTypes,
  updateFieldThunkFunction,
  Field,
} from "../fieldMetadata/redux/fieldSlice";
import { useAppSelector } from "../../redux/hooks";

interface RuleEditorProps {
  uuid: string;
  note: string;
}

export const RuleEditor = (props: RuleEditorProps): React.ReactElement => {
  const field = useAppSelector((state) => selectField(state, props.uuid));

  const dispatch = useDispatch();
  const isEditorOpen = useAppSelector(
    (state) => state.datasetSettings.isRuleEditorOpen
  );

  return (
    <div
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        backgroundColor: "lightgrey",
        overflowY: "auto",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      {isEditorOpen ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <select
            style={{
              fontSize: "16px",
              textAlign: "center",
              border: "none",
            }}
            onChange={(e) => {
              dispatch(
                updateFieldThunkFunction({
                  ...field,
                  type: e.target.value as FieldType,
                } as Field)
              );
            }}
            value={field?.type}
          >
            <option value={FieldTypes.text}>Text</option>
            <option value={FieldTypes.number}>Number</option>
            <option value={FieldTypes.json}>JSON</option>
            <option value={FieldTypes.email}>Email</option>
            <option value={FieldTypes.date}>Date</option>
            <option value={FieldTypes.url}>URL</option>
            <option value={FieldTypes.rich_text}>Rich Text</option>
            <option value={FieldTypes.list}>List</option>
          </select>
          {field?.type === FieldTypes.number ? (
            <NumberRuleEditor fieldUuid={field.uuid} />
          ) : field?.type === FieldTypes.text ? (
            <TextRuleEditor fieldUuid={field.uuid} />
          ) : field?.type === FieldTypes.json ? (
            <JsonRuleEditor fieldUuid={field.uuid} />
          ) : field?.type === FieldTypes.email ? (
            <EmailRuleEditor fieldUuid={field.uuid} />
          ) : field?.type === FieldTypes.date ? (
            <DateRuleEditor fieldUuid={field.uuid} />
          ) : field?.type === FieldTypes.url ? (
            <UrlRuleEditor fieldUuid={field.uuid} />
          ) : field?.type === FieldTypes.rich_text ? (
            <RichTextRuleEditor fieldUuid={field.uuid} />
          ) : field?.type === FieldTypes.list ? (
            <ListRuleEditor fieldUuid={field.uuid} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
