import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../redux/store";
import { v4 as uuidv4 } from "uuid";
import { BusinessService } from "../services/business.service";
import type { Business } from "../business.model";
import {
  addFailedActionStatus,
  addPendingActionStatus,
  addSuccessfulActionStatus,
} from "../../actions/redux/actionSlice";
import { _initForBusiness, Dataset } from "../../dataset/redux/datasetSlice";

interface BusinessState {
  businesses: Array<Business>;
}

const initialState: BusinessState = {
  businesses: [],
};

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    addBusiness: (
      state,
      action: PayloadAction<{
        business: Business;
      }>
    ) => {
      state.businesses.push(action.payload.business);
    },
    deleteBusiness: (state, action: PayloadAction<string>) => {
      state.businesses = state.businesses.filter(
        (business) => business.uuid !== action.payload
      );
    },
    updateBusiness: (state, action: PayloadAction<{ business: Business }>) => {
      state.businesses = state.businesses.map((business) =>
        business.uuid === action.payload.business.uuid
          ? action.payload.business
          : business
      );
    },
    setBusinesses: (state, action: PayloadAction<Array<Business>>) => {
      state.businesses = action.payload;
    },
  },
});

export const { addBusiness, deleteBusiness, updateBusiness, setBusinesses } =
  businessSlice.actions;

export const selectBusinesses = (state: RootState) => state.business.businesses;
export const selectBusinessByUuid = (state: RootState, uuid: string) =>
  state.business.businesses.find(
    (business: Business) => business.uuid === uuid
  );

export enum ActionToActionStatusKey {
  CREATE_BUSINESS = "CREATE_BUSINESS",
  DELETE_BUSINESS = "DELETE_BUSINESS",
  FETCH_BUSINESSES = "FETCH_BUSINESSES",
  UPDATE_BUSINESS = "UPDATE_BUSINESS",
  FETCH_DATASETS = "FETCH_DATASETS",
}

export const createBusinessThunk =
  (name: string): any =>
  async (dispatch: Dispatch) => {
    dispatch(
      addPendingActionStatus({
        key: ActionToActionStatusKey.CREATE_BUSINESS,
      })
    );
    const uuid = uuidv4();
    const business = {
      uuid,
      name,
    };
    try {
      await new BusinessService().genCreateBusinessX(business);
      dispatch(addBusiness({ business }));
      dispatch(
        addSuccessfulActionStatus({
          key: ActionToActionStatusKey.CREATE_BUSINESS,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: ActionToActionStatusKey.CREATE_BUSINESS,
          message: e.message,
        })
      );
    }
  };

export const deleteBusinessThunk =
  (uuid: string): any =>
  async (dispatch: Dispatch) => {
    dispatch(
      addPendingActionStatus({
        key: ActionToActionStatusKey.DELETE_BUSINESS,
      })
    );
    try {
      await new BusinessService().genDeleteBusinessX(uuid);
      dispatch(deleteBusiness(uuid));
      dispatch(
        addSuccessfulActionStatus({
          key: ActionToActionStatusKey.DELETE_BUSINESS,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: ActionToActionStatusKey.DELETE_BUSINESS,
          message: e.message ?? "An error occurred while deleting the business",
        })
      );
    }
  };

export const fetchBusinessesForUserThunk =
  (): any => async (dispatch: Dispatch) => {
    dispatch(
      addPendingActionStatus({
        key: ActionToActionStatusKey.FETCH_BUSINESSES,
      })
    );
    try {
      const businesses = await new BusinessService().genGetBusinessesForUserX();
      dispatch(setBusinesses(businesses));
      dispatch(
        addSuccessfulActionStatus({
          key: ActionToActionStatusKey.FETCH_BUSINESSES,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: ActionToActionStatusKey.FETCH_BUSINESSES,
          message: e.message ?? "An error occurred while fetching businesses",
        })
      );
    }
  };

export const updateBusinessThunk =
  (business: Business): any =>
  async (dispatch: Dispatch) => {
    dispatch(
      addPendingActionStatus({
        key: ActionToActionStatusKey.UPDATE_BUSINESS,
      })
    );
    try {
      await new BusinessService().genUpdateBusinessX(business);
      dispatch(updateBusiness({ business }));
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: ActionToActionStatusKey.UPDATE_BUSINESS,
          message: e.message ?? "An error occurred while updating the business",
        })
      );
    }
  };

export const fetchAllDatasetsByBusinessUuidThunk =
  (businessUuid: string): any =>
  async (dispatch: Dispatch) => {
    const actionEventKey = ActionToActionStatusKey.FETCH_DATASETS;
    dispatch(addPendingActionStatus({ key: actionEventKey }));
    try {
      const datasets =
        await new BusinessService().genFetchAllDatasetsByBusinessUuidX(
          businessUuid
        );
      dispatch(
        _initForBusiness(datasets.map((dataset: any) => dataset as Dataset))
      );
      dispatch(
        addSuccessfulActionStatus({
          key: actionEventKey,
        })
      );
    } catch (e: any) {
      dispatch(
        addFailedActionStatus({
          key: actionEventKey,
          message: e.message ?? "An error occurred while fetching datasets",
        })
      );
    }
  };
