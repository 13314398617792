import React from "react";

import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  ActionStatusType,
  clearActionStatusByKey,
  selectStatusByEventKey,
} from "../../../pages/actions/redux/actionSlice";
import {
  BusinessActionStatusKey,
  addUserToBusinessThunk,
} from "../redux/businessUserSlice";
import {
  BusinessRole,
  BusinessRoleToString,
  BusinessRoles,
} from "../business.model";
import { isValidEmail } from "utils";

export const RoleCreationModal = (props: {
  show: boolean;
  onHide: () => void;
  businessUuid: string;
}) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<BusinessRole>(BusinessRole.EDITOR);
  const appDispatch = useAppDispatch();
  const EVENT_KEY = BusinessActionStatusKey.ADD_USER_TO_BUSINESS;

  const status = useAppSelector((state) =>
    selectStatusByEventKey(state, EVENT_KEY)
  );

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    appDispatch(clearActionStatusByKey({ key: EVENT_KEY }));
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRole(parseInt(event.target.value) as BusinessRole);
    appDispatch(clearActionStatusByKey({ key: EVENT_KEY }));
  };

  const handleClose = () => {
    appDispatch(clearActionStatusByKey({ key: EVENT_KEY }));
    setEmail("");
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      onShow={() => {
        appDispatch(clearActionStatusByKey({ key: EVENT_KEY }));
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add user to business</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input placeholder="email" onChange={handleEmailChange} />
          <select onChange={handleRoleChange} defaultValue={role}>
            {BusinessRoles.map((role) => (
              <option value={role}>{BusinessRoleToString(role)}</option>
            ))}
          </select>
          <div>
            {status?.type === ActionStatusType.FAILURE ? (
              <div style={{ color: "red" }}>{status.message}</div>
            ) : null}
            {status?.type === ActionStatusType.SUCCESS ? (
              <div style={{ color: "green" }}>User added successfully</div>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={status?.type === ActionStatusType.PENDING}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant="primary"
          disabled={
            email === "" || status?.type != null || !isValidEmail(email)
          }
          onClick={() => {
            if (email !== "" && isValidEmail(email)) {
              appDispatch(
                addUserToBusinessThunk(email, props.businessUuid, role)
              );
            }
          }}
        >
          {status?.type === ActionStatusType.PENDING ? (
            <Spinner size="sm" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : null}
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
