import NavBar from "./NavBar";
import { Outlet } from "react-router-dom";
import { Navbar } from 'react-bootstrap';

const DasboardPage = () => {
  return (
    <div
      style={{
        width: "auto",
        height: "100vh",
        backgroundColor: "#E5E7EB",
        overflowX: "hidden",
      }}
    >
      {/* TODO(Enam):  migrate the styles tos css */}
      <div>
        <NavBar/>
        <Outlet />
      </div>
    </div>
  );
};

export default DasboardPage;
