import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { DigitTokenConfig } from "../../services/rules/DigitTokenConfig";
import { ValidationErrors } from "../../services/rules/ErrorMessage";
import { SaveButton } from "./components/SaveButton";
import {
  selectField,
  updateFieldThunkFunction,
} from "../fieldMetadata/redux/fieldSlice";
import { useDispatch } from "react-redux";
import { RuleEditorProps } from "./ruleEditorPropsInterface";

export const NumberRuleEditor = (props: RuleEditorProps) => {
  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(1000000000000000);
  const [integerOnly, setIntegerOnly] = useState<boolean>(false);
  const [roundUpto, setRoundUpto] = useState<number>(0);
  const [errors, setErrors] = useState<ValidationErrors[]>([]);

  const field = useAppSelector((state) => selectField(state, props.fieldUuid));

  const rule = field?.configStr ?? null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!rule) {
      return;
    }
    const parsedRule = JSON.parse(rule);
    const config = DigitTokenConfig.fromJson(parsedRule);
    setMinValue(config.minValue);
    setMaxValue(config.maxValue);
    setIntegerOnly(config.integerOnly);
    setRoundUpto(config.roundUpto);
  }, [rule]);

  const handleSave = () => {
    if (field == null) {
      return;
    }
    const updatedConfig = new DigitTokenConfig(
      minValue,
      maxValue,
      integerOnly,
      roundUpto
    );
    try {
      const errors: Array<ValidationErrors> = updatedConfig.validateRules();
      if (errors.length > 0) {
        setErrors(errors);
        return;
      } else {
        setErrors([]);
      }
    } catch (e: any) {
      alert(`Failed to validate rules: ${e.message}`);
      return;
    }

    dispatch(
      updateFieldThunkFunction({
        ...field,
        configStr: JSON.stringify(updatedConfig.toJson()),
      })
    );
  };

  return (
    <table>
      <tbody
        style={{
          height: "100%",
        }}
      >
        <tr>
          <td>
            <label htmlFor="min-value">Min value:</label>
          </td>
          <td>
            <input
              type="number"
              id="min-value"
              value={minValue}
              onChange={(e) => setMinValue(parseFloat(e.target.value))}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="max-value">Max value:</label>
          </td>
          <td>
            <input
              type="number"
              id="max-value"
              value={maxValue}
              onChange={(e) => setMaxValue(parseFloat(e.target.value))}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="integer-only">Integer Only:</label>
          </td>
          <td>
            <input
              type="checkbox"
              id="integer-only"
              checked={integerOnly}
              onChange={(e) => setIntegerOnly(e.target.checked)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="round-upto">Round Up To (Decimals):</label>
          </td>
          <td>
            <input
              disabled={integerOnly}
              type="number"
              id="round-upto"
              value={roundUpto || ""}
              onChange={(e) => setRoundUpto(parseFloat(e.target.value))}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            {errors.map((error) => (
              <div
                key={error.message}
                style={{
                  color: "red",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {error.message}
              </div>
            ))}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <SaveButton onSave={handleSave} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
