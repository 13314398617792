import { BaseService } from "../../../services/baseService";
import { BusinessRole, BusinessRoleInvitationStatus } from "../business.model";

export class BusinessUserService extends BaseService {
  static ROOT_URL = "/businesses/users";

  public genGetUsersForBusinessX = async (
    businessUuid: string
  ): Promise<
    Array<{
      userUuid: string;
      role: BusinessRole;
      displayName: string;
      invitationStatus: BusinessRoleInvitationStatus;
    }>
  > => {
    return await this.genAuthenticatedGetX(
      `${BusinessUserService.ROOT_URL}/${businessUuid}`
    );
  };

  public genAddUserToBusinessX = async (
    email: string,
    businessUuid: string,
    role: BusinessRole
  ): Promise<{
    userUuid: string;
    businessUuid: string;
    role: BusinessRole;
    displayName: string;
    invitationStatus: BusinessRoleInvitationStatus;
  }> => {
    return await this.genAuthenticatedPostX(
      `${BusinessUserService.ROOT_URL}/${businessUuid}`,
      {
        email,
        role,
      }
    );
  };

  public genRemoveUserFromBusinessX = async (
    businessUuid: string,
    userUuid: string
  ): Promise<void> => {
    await this.genAuthenticatedDeleteX(
      `${BusinessUserService.ROOT_URL}/${businessUuid}/${userUuid}`
    );
  };

  public genUpdateBusinessUserRoleX = async (
    businessUuid: string,
    userUuid: string,
    role: BusinessRole
  ): Promise<void> => {
    return await this.genAuthenticatedPatchX(
      `${BusinessUserService.ROOT_URL}/${businessUuid}/${userUuid}`,
      {
        role,
      }
    );
  };
}
