import { Dispatch, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";
import { setActiveCell, type ActiveCell } from "./activeCellSlice";

export interface EditorModalProps extends ActiveCell {
  shouldShowModal?: boolean;
}

const initialState: EditorModalProps = {
  datasetUuid: null,
  row: 0,
  fieldUuid: null,
  shouldShowModal: false,
};

export const inputModalSlice = createSlice({
  name: "inputModal",
  initialState,
  reducers: {
    _showModal: (state, action: PayloadAction<EditorModalProps>) => {
      return {
        ...state,
        ...action.payload,
        shouldShowModal: true,
      };
    },
    hideModal: (state) => {
      return {
        ...state,
        shouldShowModal: false,
      };
    },
  },
});

export const { _showModal, hideModal } = inputModalSlice.actions;

export const selectIsInputModalVisible = (state: RootState) =>
  state.inputModal.shouldShowModal;

export const selectActiveInputModalCell = (state: RootState) =>
  state.inputModal;

export const showEditorModalThunk = (props: EditorModalProps): any => {
  return (dispatch: Dispatch) => {
    dispatch(
      setActiveCell({
        datasetUuid: props.datasetUuid,
        row: props.row,
        fieldUuid: props.fieldUuid,
      })
    );
    dispatch(_showModal(props));
  };
};
