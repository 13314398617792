import { TokenType, TokenTypes } from "./TokenType";
import { AbstractTokenConfig } from "./AbstractTokenConfig";
import { ValidationErrors } from "./ErrorMessage";

interface RichTextConfigData {
  type: TokenType;
  maxLength: number;
  isRequired: boolean;
}

export class RichTextConfig extends AbstractTokenConfig {
  static __type: TokenType = TokenTypes.rich_text as TokenType;
  static UPPER_LIMIT = 2500; // approximately 10 KB including tags. Assuming a factor of 3 bytes of tags per character
  static DEFAULT_MAX_LENGTH = 2500;

  public constructor(
    public maxLength: number = RichTextConfig.DEFAULT_MAX_LENGTH,
    public isRequired: boolean = false
  ) {
    super(RichTextConfig.__type as TokenType);
  }

  public static fromJson(data: RichTextConfigData): RichTextConfig {
    return new RichTextConfig(data.maxLength, data.isRequired);
  }

  public toJson(): RichTextConfigData {
    return {
      type: RichTextConfig.__type,
      maxLength: this.maxLength,
      isRequired: this.isRequired,
    };
  }

  public copyWith(
    maxLength: number | undefined,
    isRequired: boolean | undefined
  ): RichTextConfig {
    return new RichTextConfig(
      maxLength === undefined ? this.maxLength : maxLength,
      isRequired === undefined ? this.isRequired : isRequired
    );
  }

  public validateRules(): Array<ValidationErrors> {
    if (this.maxLength < 0) {
      return [
        {
          isValid: false,
          message: "Max length can't be negative",
        },
      ];
    }

    if (this.maxLength === 0 && this.isRequired) {
      return [
        {
          isValid: false,
          message: "Max length can't be 0 if input is required",
        },
      ];
    }
    if (this.maxLength > RichTextConfig.UPPER_LIMIT) {
      return [
        {
          isValid: false,
          message: `Max length must be less than ${RichTextConfig.UPPER_LIMIT}`,
        },
      ];
    }
    return [];
  }

  public validateInput(input: string): Array<ValidationErrors> {
    if (input === "" && this.isRequired) {
      return [{ isValid: false, message: "Input is required" }];
    }
    if (input.length > RichTextConfig.UPPER_LIMIT) {
      return [
        {
          isValid: false,
          message: `Input length must be less than ${RichTextConfig.UPPER_LIMIT}`,
        },
      ];
    }
    if (input.length > this.maxLength) {
      return [
        {
          isValid: false,
          message: `Input length must be less than ${this.maxLength}`,
        },
      ];
    }
    return [];
  }
}
