import { useState } from "react";
import { Button, ButtonGroup, Offcanvas } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { toggleRuleEditor } from "../redux/detasetSettingsSlice";
import { RuleEditor } from "../../rules/RuleEditor";
import {
  deleteFieldThunkFunction,
  selectField,
} from "../../fieldMetadata/redux/fieldSlice";
import { useDispatch } from "react-redux";

enum Tab {
  VALIDATION,
  TRANSFORMATION,
}

export const RuleEditorContainer = () => {
  const isEditorOpen = useAppSelector(
    (state) => state.datasetSettings.isRuleEditorOpen
  );

  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const activeCell = useAppSelector((state) => state.activeCell);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.VALIDATION);
  const field = useAppSelector((state) =>
    selectField(state, activeCell.cell.fieldUuid!)
  );

  return (
    <Offcanvas
      show={isEditorOpen}
      onHide={() => {
        appDispatch(toggleRuleEditor(false));
      }}
      backdrop={false}
      scroll={true}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{field?.name || ""}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ButtonGroup
          style={{
            width: "100%",
            border: "none",
          }}
        >
          <Button
            style={getStyle(activeTab === Tab.VALIDATION)}
            onClick={() => {
              setActiveTab(Tab.VALIDATION);
            }}
          >
            Validation
          </Button>
          <Button
            style={getStyle(activeTab === Tab.TRANSFORMATION)}
            onClick={() => {
              setActiveTab(Tab.TRANSFORMATION);
            }}
          >
            Transformation
          </Button>
        </ButtonGroup>

        {activeTab === Tab.VALIDATION ? (
          <RuleEditor uuid={activeCell.cell.fieldUuid!} note={""} />
        ) : (
          <p>Unimplementetd Transformation tab</p>
        )}
        <div
          style={{
            textAlign: "right",
            marginTop: "10px",
          }}
        >
          <Button
            onClick={() => {
              if (field?.uuid != null) {
                dispatch(deleteFieldThunkFunction(field.uuid));
              }
            }}
            variant="danger"
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              appDispatch(toggleRuleEditor(false));
            }}
            variant="secondary"
            style={{
              marginLeft: "10px",
            }}
          >
            Close
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const getStyle = (isActive: boolean) => {
  return isActive
    ? {
        backgroundColor: "#7749F8",
        color: "white",
      }
    : {
        backgroundColor: "#F8F9FA",
        color: "#7749F8",
      };
};
