import React from "react";

import { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { handleForgotPasswordThunk } from "./redux/authSlice";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import "./SignIn.css";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const dispatch = useDispatch();
  return (
    <div className="signIn-page">
      <h1>Reset Password</h1>
      <Form>
        {isEmailSent ? (
          <>
            <p
              style={{
                color: "green",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              We have sent you an email with instructions to reset your
              password.
            </p>
            <p style={{ textAlign: "center" }}>
              If you don't see the email, please check your spam folder.
            </p>
          </>
        ) : (
          <>
            <Form.Group>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-3"
                style={{ width: "500px" }}
              />
            </Form.Group>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="primary"
                onClick={() => {
                  dispatch(
                    handleForgotPasswordThunk(
                      email,
                      setErrorMessage,
                      setIsEmailSent
                    )
                  );
                }}
              >
                Send password reset email
              </Button>
            </div>
            <div style={{ textAlign: "center" }}>
              {errorMessage && (
                <Alert variant="danger" className="mt-3">
                  {errorMessage}
                </Alert>
              )}
              <p className="mt-3">
                I know my password <a href="/auth/SignIn">Sign In</a>
              </p>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default ForgotPassword;
