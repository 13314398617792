import { useDispatch } from "react-redux";

import { useAppSelector } from "../../redux/hooks";
import { setActiveCell } from "./redux/activeCellSlice";
import {
  INIT_DATASET_FIELDS_ACTION_EVENT,
  upsertDataThunkFunction,
} from "./redux/dataSlice";
import {
  ActionStatusType,
  selectStatusByEventKey,
} from "../actions/redux/actionSlice";
import {
  toggleCellEditor,
} from "./redux/detasetSettingsSlice";

interface InlineInputCellProps {
  datasetUuid: string;
  row: number;
  fieldUuid: string;
  isValid: boolean;
  isActive: boolean;
  value: string;
  setValue: (value: string) => void;
}

export const InlineInputCell = (
  props: InlineInputCellProps
): React.ReactElement => {
  const appDispatch = useDispatch();
  const dispatch = useDispatch();

  const actionEventStatus = useAppSelector((state) =>
    selectStatusByEventKey(state, INIT_DATASET_FIELDS_ACTION_EVENT)
  );
  return (
    <input
      type="text"
      placeholder={""}
      value={props.value}
      disabled={actionEventStatus?.type === ActionStatusType.PENDING}
      id={`inputCell-${props.row}-${props.fieldUuid}`}
      style={{
        width: "100%",
        fontSize: "16px",
        textAlign: "center",
        border: 0,
        height: "100%",
        padding: "0",
        backgroundColor: !props.isValid
          ? "#DC35450D"
          : props.isActive
          ? "#0DEF4C26"
          : "white",
        color: !props.isValid ? "#DC3545" : "black",
      }}
      onChange={(e) => {
        props.setValue(e.target.value);
        dispatch(
          upsertDataThunkFunction({
            row: props.row,
            fieldUuid: props.fieldUuid,
            value: e.target.value,
            datasetUuid: props.datasetUuid,
          })
        );
      }}
      onClick={(_e) => {
        appDispatch(
          setActiveCell({
            datasetUuid: props.datasetUuid,
            row: props.row,
            fieldUuid: props.fieldUuid,
          })
        );
      }}
      onFocus={(_e) => {
        appDispatch(
          setActiveCell({
            datasetUuid: props.datasetUuid,
            row: props.row,
            fieldUuid: props.fieldUuid,
          })
        );
      }}
      onDoubleClick={(_e) => {
        appDispatch(
          setActiveCell({
            datasetUuid: props.datasetUuid,
            row: props.row,
            fieldUuid: props.fieldUuid,
          })
        );
        appDispatch(toggleCellEditor(true));
      }}
    />
  );
};
