import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { createFirebaseAccount } from "./services/authServices";
import { Form, Button, Alert } from "react-bootstrap";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <div className="signIn-page">
      <h1>Sign Up</h1>
      <Form>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
            className="mb-3"
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
            className="mb-3"
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e: any) => {
              setPassword(e.target.value);
            }}
            className="mb-3"
            style={{ width: "400px" }}
          />
        </Form.Group>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="primary"
            onClick={async () => {
              setLoading(true);
              try {
                await createFirebaseAccount(email, password, name);
                setSuccessMessage("Account created successfully");
              } catch (error: any) {
                setErrorMessage(error.message ?? error);
              } finally {
                setLoading(false);
              }
            }}
            disabled={loading} // Disable button while loading
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Sign Up"
            )}
          </Button>
        </div>
      </Form>
      <div style={{ textAlign: "center" }}>
        {errorMessage && (
          <Alert variant="danger" className="mt-3">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert variant="success" className="mt-3">
            {successMessage}
          </Alert>
        )}
      </div>
    </div>
  );
};

export { SignUp };
